/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Hosts from '../../global/Hosts'
import Utils from '../../components/Utils'
import $ from 'jquery';

class NavBar2 extends Component {

    constructor(props){
        super(props)

        this.state = {
            menuActive: 0
        }

        this.retornaListaMenu = this.retornaListaMenu.bind(this)
        this.retornaListaMenuEn = this.retornaListaMenuEn.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
    }   


    redirecionarAreaProfessor=()=>{
        if(Utils.getCookie('lumUserId')!==null && Utils.getCookie('lumUserId')!=='00000000D00000000000000000000002'){
            return (
                <React.Fragment>
                    {this.props.lang === 'pt' &&  <a href={Hosts['areaDoProfessor']} id='area_professor' className="not_smooth" title="Área do Professor">Área do Professor</a>}
                    {this.props.lang === 'en' &&  <a href={Hosts['areaDoProfessorEn']} id='area_professor' className="not_smooth" title="Teacher's Area">Teacher's Area</a>}
                </React.Fragment>
            )
        }else{
            return (
                <React.Fragment>
                    {this.props.lang === 'pt' &&  <a href={Hosts['Login']} id='area_professor' className="not_smooth" title="Área do Professor">Área do Professor</a>}
                    {this.props.lang === 'en' &&  <a href={Hosts['LoginEn']} id='area_professor/en' className="not_smooth" title="Teacher's Area">Teacher's Area</a>}
                </React.Fragment>
            );
        }
    }

    redirecionarExtraResources=()=>{
        if(Utils.getCookie('lumUserId')!==null && Utils.getCookie('lumUserId')!=='00000000D00000000000000000000002'){
            return (
                <React.Fragment>
                    {this.props.lang === 'pt' &&  <a href={'area-do-professor/index.html'} className="linkAcessar" title="Botão acessar Teacher's Extra Resources" id='area_professor'/>}
                    {this.props.lang === 'en' &&  <a href={'#/area-do-professor/index.html/en'} className="linkAcessar" title="Teacher's Extra Resources" id='area_professor'/>}
                </React.Fragment>
            )

        }else{
            return (
                <React.Fragment>
                    {this.props.lang === 'pt' &&  <a href={Hosts['Login']} className="linkAcessar" title="Botão acessar Teacher's Extra Resources" id='area_professor'></a>}
                    {this.props.lang === 'en' &&  <a href={Hosts['Login']} className="linkAcessar#/en" title="Teacher's Extra Resources" id='area_professor'></a>}
                </React.Fragment>
            );
        }
    }

    redirecionarAreaProfessorList=()=>{
        if(Utils.getCookie('lumUserId')!==null && Utils.getCookie('lumUserId')!=='00000000D00000000000000000000002'){
            return (
                <React.Fragment>
                    {this.props.lang === 'pt' &&  <li className="menuM"><a href={'area-do-professor/index.html'} id='area_professor' className="not_smooth" title="Área do Professor">Área do Professor</a></li>}
                    {this.props.lang === 'en' &&  <li className="menuM"><a href={'area-do-professor/index.html#/en'} id='area_professor' className="not_smooth" title="Teacher's Area">Teacher's Area</a></li>}
                </React.Fragment>
            )
        }else{
            return (
                <React.Fragment>
                    {this.props.lang === 'pt' &&  <li className="menuM"><a href={Hosts['Login']} title="Área do Professor">Área do Professor</a></li>}
                    {this.props.lang === 'en' &&  <li className="menuM"><a href={Hosts['Login']} title="Teacher's Area">Teacher's Area</a></li>}
                </React.Fragment>
            );
        }
    }
    
    handleScroll(event){
      event.preventDefault();

        const elementoSelecionado = event.target.getAttribute('href')
        let menuActiveClick = document.getElementsByClassName('logo')
        let bodyOverflow = document.getElementsByTagName('body')

        const go = (elem) => {
            if (window.innerWidth) {
                $('html, body')
                 .animate({ scrollTop: $(elementoSelecionado).offset().top }, 800);
            }
            menuActiveClick[0].className = 'row logo'
            bodyOverflow[0].style = ''
        }
        
        go(elementoSelecionado)

    }

    retornaListaMenu(){  
        return(
            <React.Fragment>
                <li className="apresentacao linkMenu">
                    <a href="#apresentacao" onTouchStart={this.handleScroll} onClick={this.handleScroll} title="Apresentação">
                        Apresentação
                    </a>
                </li>
                <li className="ipadOff linkMenu">
                    <a href="#destaque" onClick={this.handleScroll} onTouchStart={this.handleScroll} title="Destaques desta Edição">
                        Destaques Desta Edição
                    </a>
                </li>
                <li className="ipad linkMenu">
                    <a href="#destaques" onTouchStart={this.handleScroll} onClick={this.handleScroll}title="Destaques desta Edição">
                        Destaques Desta Edição
                    </a>
                </li>
                <li className="linkMenu">
                    <a href="#digitalpack" onClick={this.handleScroll} onTouchStart={this.handleScroll} title="Digital Pack">
                        Digital Pack
                    </a>
                </li>
                <li className="linkMenu">
                    <a href="#posadocao" onClick={this.handleScroll} onTouchStart={this.handleScroll} title="Teacher's Extra Resources">
                        Teacher's Extra Resources
                    </a>
                </li>
                <li className="linkMenu">
                    <a href="#universo" onClick={this.handleScroll} onTouchStart={this.handleScroll} title="Universo Kids' Web">
                        Universo Kids' Web
                    </a>
                </li>
                <li className="linha">
                    <div></div>
                </li>
                <li className="linkMenu">
                    {this.redirecionarAreaProfessor()}
                </li>
                <div className="dropdown">
                    <a className="dropbtn" title="Área do Aluno">Área do Aluno</a>
                    <div className="dropdown-content">
                        <a href={Hosts['pastimes']} className="not_smooth" title="Kids' Web Pastimes">Kids' Web Pastimes</a>
                        <Link to={Hosts['localhost'] + "emocionais"} className="not_smooth" title="Animações Socioemocionais">Animações Socioemocionais</Link>
                        <Link to={Hosts['localhost'] + "clipes"} className="not_smooth" title="Clipes Musicais">Clipes Musicais</Link>
                        <Link to={Hosts['localhost'] + "games"} className="not_smooth" title="Kids' Web Games">Kids' Web Games</Link>
                    </div>
                </div>
            </React.Fragment>

            )        
        }

        retornaListaMenuEn(){
            return(
                <React.Fragment>
                    <li className="apresentacao linkMenu">
                        <a href="#apresentacao" onClick={this.handleScroll} onTouchStart={this.handleScroll} title="Presentation">
                            Presentation
                        </a>
                    </li>
                    <li className="ipadOff linkMenu">
                        <a href="#destaque" onClick={this.handleScroll} onTouchStart={this.handleScroll} title="This Edition's Highlights">
                            This Edition's Highlights
                        </a>
                    </li>
                    <li className="ipad linkMenu">
                        <a href="#destaques" onClick={this.handleScroll} onTouchStart={this.handleScroll} title="This Edition's Highlights">
                            This Edition's Highlights
                        </a>
                    </li>
                    <li className="linkMenu">
                        <a href="#digitalpack" onClick={this.handleScroll} onTouchStart={this.handleScroll} title="Digital Pack">
                            Digital Pack
                        </a>
                    </li>
                    <li className="linkMenu">
                        <a href="#posadocao" onClick={this.handleScroll} onTouchStart={this.handleScroll} title="Teacher's  Extra Resources">
                            Teacher's Extra Resources
                        </a>
                    </li>
                    <li className="linkMenu">
                        <a href="#universo" onClick={this.handleScroll} onTouchStart={this.handleScroll} title="Kids' Web Universe">
                            Kids' Web Universe
                        </a>
                    </li>
                    <li className="linha">
                        <div></div>
                    </li>
                    <li className="linkMenu">
                        {this.redirecionarAreaProfessor()}
                    </li>
                    <div className="dropdown">
                        <a className="dropbtn" title="Student's Area">Student's Area</a>
                        <div className="dropdown-content">
                            <a href={Hosts['pastimes']} title="Kids' Web Pastimes" className="not_smooth">Kids' Web Pastimes</a>
                            <Link to={Hosts['localhost'] + "emocionais/en"} title="Socioemotional Animations" className="not_smooth">Socioemotional Animations</Link>
                            <Link to={Hosts['localhost'] + 'clipes/en'} title="Musical Clips" className="not_smooth">Musical Clips</Link>
                            <Link to={Hosts['localhost'] + "games/en"}  title="Kids' Web Games" className="not_smooth">Kids' Web Games</Link>
                        </div>
                    </div>
                </React.Fragment>
            )
        }

    render() {

        return (
            <React.Fragment>
                <div className="container">
                    <div className="col-xs-12 menu">
                        <ul className="list-unstyled list-group list-group-horizontal nav">
                            {this.props.lang === 'pt' && this.retornaListaMenu()}
                            {this.props.lang === 'en' && this.retornaListaMenuEn()}
                        </ul>
                    </div>
                </div>
                <div className="navMenu">
                    <a href="javascript:void(0);"><span>Menu</span></a>
                </div>
                <div className="col-xs-12 menu-responsivo">
                    <div className="container">
                        <div className="col-xs-12">
                            <ul className="list-unstyled list-group list-group-horizontal nav">
                                {this.props.lang === 'pt' &&  <li className="noPadd"><Link to={Hosts['localhost']} className="noPadd" title="Kids' Web"><img className="img-fluid" src={Hosts['images']+"logo.png"} alt="Kids' Web"/></Link></li>}
                                {this.props.lang === 'en' &&  <li className="noPadd"><Link to={Hosts['localhost']} className="noPadd" title="Kids' Web"><img className="img-fluid" src={Hosts['images']+"logo.png"} alt="Kids' Web"/></Link></li>}
                                {this.props.lang === 'pt' && this.retornaListaMenu()}
                                {this.props.lang === 'en' && this.retornaListaMenuEn()}
                            </ul>                        
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


export default NavBar2