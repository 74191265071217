import React from 'react'
import MenuButtonInfo from './pt/MenuButtonInfo'


class MenuButtons extends React.Component{
    
    constructor(){
        super();
        this.state={
            activeIdInfo:'clilinfo',
            buttons:[
                {
                        divClassName:'col-xs-12 col-sm-12 col-md-3 item clilImg-verde',
                        divId:'clil',
                        pClassName:'txtclil active',
                        aTitle:'CLIL and Life Skills',
                        aHref:'#CLIL and Life Skills',
                        innerText:'CLIL AND LIFE SKILLS'
                },
                {
                    divClassName:'col-xs-12 col-sm-12 col-md-3 item glossImg',
                    divId:'glossario',
                    pClassName:'',
                    aTitle:'Glossário Interativo',
                    aHref:'#Glossário Interativo',
                    innerText:'GLOSSÁRIO INTERATIVO'
                },
                {
                    divClassName:'col-xs-12 col-sm-12 col-md-3 item bnccImg',
                    divId:'bncc',
                    pClassName:'',
                    aTitle:'Alinhamento com a BNCC',
                    aHref:'#Alinhado à BNCC',
                    innerText:'ALINHAMENTO COM A BNCC'
                },
                {
                    divClassName:'col-xs-12 col-sm-12 col-md-3 item bookImg',
                    divId:'booklet',
                    pClassName:'',
                    aTitle:'Booklet para a Família',
                    aHref:'#Booklet para a Família',
                    innerText:'BOOKLET PARA A FAMÍLIA'
                }
            ]
        }
    }



    render(){
      let rows = []  
            this.state.buttons.forEach((button)=>{
                rows.push(<div className={button.divClassName} id={button.divId} key = {button.divId}>
                    <p className={button.pClassName}>
                        <a title={button.aTitle} href='javascript:void(0)'>
                            {button.innerText}
                        </a>
                    </p>
                </div>)                  
            })
        return (
            
                <div className="row ButtonsImg">                           
                    <div className="container">
                        {rows}
                    </div>
                </div>
             
        );
    }
}

export default MenuButtons