import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Hosts from '../../global/Hosts'
import axios from 'axios'

class Idioma extends Component {

    constructor(props){
        super(props)
        this.state={
            idioma: this.props.idioma || '',
            url: this.props.url,
            titleEn: [
                'English Language', 'Portuguese Language'
            ],
            titlePt: [
                'Idioma Inglês', 'Idioma Português'
            ]
        }
    }

    logout(){
        localStorage.clear()
        document.cookie="lumUserId=00000000D00000000000000000000002;path=/;"
        window.location = Hosts['Logout']; 
        // axios.get(Hosts['Logout']).finally(()=> window.location = Hosts['localhost'])
    }


    render() {
        return (
                
            this.state.idioma === 'en' ? (
                <div className="idiomaDiv">
                    <Link to={Hosts['localhost']+this.state.url} title={this.state.titleEn[1]} className="idioma">PT</Link>
                    <span className="idioma separador"> | </span>
                    <a href="javascript:void(0)" title={this.state.titleEn[0]} className={`idioma idioma2 ${this.props.idioma == 'en' ? 'idioma-active' : ''}`}>EN</a>
                    {this.props.professorValid ? <React.Fragment><a className="login" href={Hosts['minhaContaRichmond']} title="My account" aria-label="My account"><img src={Hosts["images"]+"/bt_login.png"} alt="Botão Acessar Perfil" /></a>                         <a className="sair" onClick={()=> this.logout()} title="Exit" aria-label="Exit"><img src={Hosts["images"]+"/btn_sair.png"} alt="Botão Fazer Logoff" /></a></React.Fragment>:''}
                </div>
            ) : (
                <div className="idiomaDiv">
                    <a href="javascript:void(0)" title={this.state.titlePt[1]} className={`idioma ${this.props.idioma == 'pt' ? 'idioma-active' : ''}`}>PT</a>
                    <span className="idioma separador"> | </span>
                    <Link to={Hosts['localhost']+this.state.url} title={this.state.titlePt[0]} className={`idioma idioma2`}>EN</Link>
                    {this.props.professorValid ? <React.Fragment><a className="login" href={Hosts['minhaContaRichmond']} title={"Minha conta"} aria-label="Minha conta"><img src={Hosts["images"]+"/bt_login.png"} alt="Botão Acessar Perfil" /></a><a className="sair" onClick={()=> this.logout()} title="Sair" aria-label="Sair"><img src={Hosts["images"]+"/btn_sair.png"} alt="Botão Fazer Logoff" /></a></React.Fragment>:''}
                </div>
            )                
            
        )
    }
}

export default Idioma