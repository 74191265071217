import Hosts from '../global/Hosts'
import $ from 'jquery'
import jQuery from 'jquery'
const urlImage = Hosts['images']

const events = {

    
    docReady:function(){

    $(".barraAcessivel").KidsWebAcessibilidade();
    
    $("ul.pull-right > li:eq(0) > a").click(function(){
        $("#menuPrincipal li:eq(2) a").click();
        return false;
    });
    $("ul.pull-right > li:eq(4) > a").click(function(){
        $("#menuPrincipal li:eq(1) a").click();
        return false;
    });

    var windowWidth = window.innerWidth;
   
    if (windowWidth > 768 && !/webOS|iPad|iPod|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        $( '.owl-prev' ).on({
            mouseenter: function() {
                $(this).find("img").attr('src', urlImage+'btLeft-hover.png')
            }, mouseleave: function() {
                $(this).find("img").attr('src', urlImage+'btLeft.png')
            }
        });
    
        $( '.owl-next' ).on({
            mouseenter: function() {
                $(this).find("img").attr('src', urlImage+'btRight-hover.png')
            }, mouseleave: function() {
                $(this).find("img").attr('src', urlImage+'btRight.png')
            }
        });

        $(".owl-prev").on("mousedown", function() {
            $(this).find("img").attr('src', urlImage+'btLeft-click.png')
        });
    
        $(".owl-prev").on("mouseup", function() {
            $(this).find("img").attr('src', urlImage+'btLeft-hover.png')
        });
    
        $(".owl-next").on("mousedown", function() {
            $(this).find("img").attr('src', urlImage+'btRight-click.png');
        });
    
        $(".owl-next").on("mouseup", function() {
            $(this).find("img").attr('src', urlImage+'btRight-hover.png')
        });
    }



    $(".owl-next").on("touchstart", function() {
        $(this).find("img").attr('src', urlImage+'btRight-click.png');
    });

    $(".owl-next").on("touchend", function() {
        $(this).find("img").attr('src', urlImage+'btRight.png');
    });

    $(".owl-prev").on("touchstart", function() {
        $(this).find("img").attr('src', urlImage+'btLeft-click.png')
    });

    $(".owl-prev").on("touchend", function() {
        $(this).find("img").attr('src', urlImage+'btLeft.png')
    });

    if($('body').css('overflow') == 'hidden'){
        $('body').css('overflow', 'visible')
    }

    if(windowWidth <= 800 && /webOS|iPad|iPod|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        $('a.dropbtn').on('touchstart', function(event){
            event.preventDefault()
            console.log(event);
            $('.dropdown-content').toggle()
        })
    }

    // var windowWidth = window.innerWidth;
    // if(windowWidth <= 800 && /webOS|iPad|iPod|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    //     $('a.dropbtn').on('touchstart', function(event){
    //         event.preventDefault()
    //         $('.dropdown-content').toggle()
    //     })

    //     $('.nav a[href^="#"]:not(.not_smooth)').on('touchstart', function(e) {
    //         e.preventDefault();
    //         var id = $(this).attr('href'),
    //         targetOffset = $(id).offset().top;
                    
    //         $('html, body').animate({ 
    //             scrollTop: targetOffset - 100
    //         }, 1000, function() {
    
    //             if (windowWidth <= 768) {
    //                 if($('.logo').hasClass('menuactive')){
    //                     $('.logo').removeClass('menuactive')
    //                     $('body').css('overflow', 'visible')
    //                 }
    //                 $('a.dropbtn').on('touchstart', function(event){
    //                     event.preventDefault()
    //                     $('.dropdown-content').toggle()
    //                 })
    //             }
    //         });
    //     });
    // }else if (windowWidth <= 768) { 
    //     $('a.dropbtn').on('touchstart', function(event){
    //         event.preventDefault()
    //         $('.dropdown-content').toggle()
    //     })
    //     $('.nav a[href^="#"]:not(.not_smooth)').on('touchstart', function(e) {
    //         e.preventDefault();
    //         var id = $(this).attr('href'),
    //         targetOffset = $(id).offset().top;
                    
    //         $('html, body').animate({ 
    //             scrollTop: targetOffset - 100
    //         }, 1000, function() {
    
    //             if (windowWidth <= 768) {
    //                 if($('.logo').hasClass('menuactive')){
    //                     $('.logo').removeClass('menuactive')
    //                     $('body').css('overflow', 'visible')
    //                 }
    //                 $('a.dropbtn').on('touchstart', function(event){
    //                     event.preventDefault()
    //                     $('.dropdown-content').toggle()
    //                 })
    //             }
    //         });
    //     });
    // }else{
        
    //     // Smooth Scroll do menu
    //     $('.nav a[href^="#"]:not(.not_smooth)').on('click', function(e) {
    //         e.preventDefault();
    //         var id = $(this).attr('href'),
    //         targetOffset = $(id).offset().top;
    //         var windowWidth = window.innerWidth;
    //         if (windowWidth <= 768) { 
    //             $('.dropdown-content').toggle()
    //         }
                    
    //         $('html, body').animate({ 
    //             scrollTop: targetOffset - 100
    //         }, 1000, function() {
    
    //             if (windowWidth <= 768) {
    //                 if($('.logo').hasClass('menuactive')){
    //                     $('.logo').removeClass('menuactive')
    //                     $('body').css('overflow', 'visible')
    //                 }
    //                 $('a.dropbtn').on('touchstart', function(event){
    //                     event.preventDefault()
    //                     $('.dropdown-content').toggle()
    //                 })
    //             }
    //         });
    //     });

    // }

    $( ".linkMenu" ).click(function() {
        if ($(this).hasClass("quebra")) {
            $( ".linkMenu" ).removeClass("quebra");
            $(this).addClass("ativo");
        }
    });

    $( "a.fim" ).click(function( event ) {
        event.preventDefault();
        $("html, body").animate({ scrollTop: $($(this).attr("href")).offset().top }, 1000);
    });

    $( "a.destaque" ).click(function( event ) {
        event.preventDefault();
        $("html, body").animate({ scrollTop: $($(this).attr("href")).offset().top }, 1000);
    });

    // muda as classes dos 4 itens
    $( "#clil" ).click(function() {

        $("#glossarioinfo").addClass("off");
        $("#bookletinfo").addClass("off");
        $("#bnccinfo").addClass("off");
        $("#clilinfo").removeClass("off");

        $( "#booklet" ).find("p").removeClass("active");
        $( "#glossario" ).find("p").removeClass("active");
        $( "#bncc" ).find("p").removeClass("active");
        $( "#clil" ).find("p").addClass("active");

        $( "#clil" ).removeClass("clilImg");
        $( "#clil" ).addClass("clilImg-verde");
        $( "#glossario" ).removeClass("glossImg-verde");
        $( "#glossario" ).addClass("glossImg");
        $( "#bncc" ).removeClass("bnccImg-verde");
        $( "#bncc" ).addClass("bnccImg");
        $( "#booklet" ).removeClass("bookImg-verde");
        $( "#booklet" ).addClass("bookImg");

    });

    $( "#glossario" ).click(function() {

        $("#clilinfo").addClass("off");
        $("#bookletinfo").addClass("off");
        $("#bnccinfo").addClass("off");
        $("#glossarioinfo").removeClass("off");

        $( "#booklet" ).find("p").removeClass("active");
        $( "#clil" ).find("p").removeClass("active");
        $( "#bncc" ).find("p").removeClass("active");
        $( "#glossario" ).find("p").addClass("active");

        $( "#glossario" ).removeClass("glossImg");
        $( "#glossario" ).addClass("glossImg-verde");
        $( "#clil" ).removeClass("clilImg-verde");
        $( "#clil" ).addClass("clilImg");
        $( "#bncc" ).removeClass("bnccImg-verde");
        $( "#bncc" ).addClass("bnccImg");
        $( "#booklet" ).removeClass("bookImg-verde");
        $( "#booklet" ).addClass("bookImg");
    });

    $( "#bncc" ).click(function() {

        $("#glossarioinfo").addClass("off");
        $("#bookletinfo").addClass("off");
        $("#clilinfo").addClass("off");
        $("#bnccinfo").removeClass("off");

        $( "#booklet" ).find("p").removeClass("active");
        $( "#glossario" ).find("p").removeClass("active");
        $( "#clil" ).find("p").removeClass("active");
        $( "#bncc" ).find("p").addClass("active");

        $( "#bncc" ).removeClass("bnccImg");
        $( "#bncc" ).addClass("bnccImg-verde");
        $( "#clil" ).removeClass("clilImg-verde");
        $( "#clil" ).addClass("clilImg");
        $( "#glossario" ).removeClass("glossImg-verde");
        $( "#glossario" ).addClass("glossImg");
        $( "#booklet" ).removeClass("bookImg-verde");
        $( "#booklet" ).addClass("bookImg");
    });

    $( "#booklet" ).click(function() {

        $("#glossarioinfo").addClass("off");
        $("#clilinfo").addClass("off");
        $("#bnccinfo").addClass("off");
        $("#bookletinfo").removeClass("off");

        $( "#clil" ).find("p").removeClass("active");
        $( "#glossario" ).find("p").removeClass("active");
        $( "#bncc" ).find("p").removeClass("active");
        $( "#booklet" ).find("p").addClass("active");

        $( "#booklet" ).removeClass("bookImg");
        $( "#booklet" ).addClass("bookImg-verde");
        $( "#clil" ).removeClass("clilImg-verde");
        $( "#clil" ).addClass("clilImg");
        $( "#glossario" ).removeClass("glossImg-verde");
        $( "#glossario" ).addClass("glossImg");
        $( "#bncc" ).removeClass("bnccImg-verde");
        $( "#bncc" ).addClass("bnccImg");
    });

    $('.navMenu').find('a').on('click', function(e){
        e.preventDefault();
        if($('.logo').hasClass('menuactive')){
            $('.logo').removeClass('menuactive')
            $('body').css('overflow', 'visible')
        }else{
            $('.logo').addClass('menuactive')
            $('body').css('overflow', 'hidden')
        }
    })

    $(window).on("scroll", function() {
            var windowWidth = window.innerWidth;
            if (windowWidth > 768 ) {
                if (navigator.userAgent.match(/Mozzila/) || navigator.userAgent.match(/Firefox/) ) {
                    $('body').css({ 'transform': '', 'overflow': '', 'transform-origin': '' });

                    // let top = $(this.window).scrollTop();
                    // $('#main .home .logo .menu-responsivo').css({ 'position': 'absolute', 'display': 'block', 'top': `${top }px` } )
                 }
                 
                if($(window).scrollTop() > 260) {
                    $( ".menu-responsivo" ).slideDown( "medium" );
                } else {
                   $( ".menu-responsivo" ).slideUp("slow");
                }
                 
            } else {
                $( ".menu-responsivo" ).hide();
            }
    });

    $("#voltarAoTopo").on("click", function() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    })

    // pinta o collapse de outra cor
    $(".conteudoCollapse > a").on("click", function(event) {
        event.preventDefault();
        $(this).parent().toggleClass("active");
        $(this).parent().find("div.collapse").toggle();
    })

    // troca imagem do menu
    $("#checkMenu").on("click", function() {
        $(".hambMenu").toggleClass("ativo");
    });

    // pinta os links do menu mobile
    if($('#apresentacao').offset()!=undefined){
        var top1 = $('#apresentacao').offset().top;
        var top2 = $('#destaque').offset().top;
        var top3 = $('#digitalpack').offset().top;
        var top4 = $('#posadocao').offset().top;
        var top5 = $('#universo').offset().top;
    }
    
    
    var menu1 = $('#menu1');
    var menu2 = $('#menu2');
    var menu3 = $('#menu3');
    var menu4 = $('#menu4');
    var menu5 = $('#menu5');
    
    //no click
    $(".menuM").on("click", function() {
        $("#menu li").removeClass("ativo");
        $(this).toggleClass("ativo");
    });

    // Smooth Scroll do menu
    menu1.on("click", function(e) {
        e.preventDefault();
        $('html, body').animate( {scrollTop: top1 + 10}, 1000)
    });
    menu2.on("click", function(e) {
        e.preventDefault();
        $('html, body').animate( {scrollTop: top2 + 10}, 1000)
    });
    menu3.on("click", function(e) {
        e.preventDefault();
        $('html, body').animate( {scrollTop: top3 + 10}, 1000)
    });
    menu4.on("click", function(e) {
        e.preventDefault();
        $('html, body').animate( {scrollTop: top4 + 10}, 1000)
    });
    menu5.on("click", function(e) {
        e.preventDefault();
        $('html, body').animate( {scrollTop: top5 + 10}, 1000)
    });

    // no scroll
    $(document).on("scroll", function() {
        var scrollPos = $(document).scrollTop();
        if (scrollPos < 1250) {
            $("#menu li").removeClass("ativo");
            menu1.toggleClass("ativo");
        } else if (scrollPos >= top2 && scrollPos < top3) {
            $("#menu li").removeClass("ativo");
            menu2.toggleClass("ativo");
        } else if (scrollPos >= top3 && scrollPos < top4) {
            $("#menu li").removeClass("ativo");
            menu3.toggleClass("ativo");
        } else if (scrollPos > top4 && scrollPos < top5) {
            $("#menu li").removeClass("ativo");
            menu4.toggleClass("ativo");
        } else if (scrollPos > top5) {
            $("#menu li").removeClass("ativo");
            menu5.toggleClass("ativo");
        }
    });
    }

}

jQuery.fn.KidsWebAcessibilidade = function(options) {

    //Seta os valores default
    var defaults = {
        nomeCookieFiltro: "BarraAcessibilidadeFiltro",
        nomeCookieZoom: "BarraAcessibilidadeZoom",
        caminhoCss: Hosts['css'],
        zoomEscala: true,
        zoomMaximo: 8,
        zoomPorcentagem: 25,
        elementosMudancaFonte: "h1, h2, h3, h4, h5, h6, p, a, span, label, input, textarea, li, pre",
        excluirElementos: ['.nofilter, img'],
        excluirElementosAP: ['img'],
        codOrigem: '4',
        urlsCookie: []
    }

    var options = $.extend(defaults, options);

    //Inicializacao do plugin
    return $.each(function() {

        //
        //	Objeto principal
        //
        var obj = jQuery(this);
        
        //
        //	Remove os botoes da correspondentes da barra caso a pagina nao tenha conteudo, menu, busca ou rodape
        //
        if($('#conteudo').length <= 0){
            $('body .barraAcessivel ul:first-child li[role="menuitem"]:first-child').hide();
        }
        if($('#menuPrincipal').length <= 0){
            $('body .barraAcessivel ul:first-child li[role="menuitem"]:nth-child(2)').hide();
        }
        if($('#txtBusca').length <= 0){
            $('body .barraAcessivel ul:first-child li[role="menuitem"]:nth-child(3)').hide();
        }
        if($('#rodape').length <= 0){
            $('body .barraAcessivel ul:first-child li[role="menuitem"]:nth-child(4)').hide();
        }

        //
        //	Verificação se é firefox
        //
        var isFirefox = false;
        if ( navigator.userAgent.match(/Mozzila/) || navigator.userAgent.match(/Firefox/) ){
            isFirefox = true;
        }

        //
        //	Verificação se é Internet Explorer
        //
        var isIE = false;
        if (navigator.appName == 'Microsoft Internet Explorer' || !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/)) || (typeof $.browser !== "undefined" && $.browser.msie == 1)) {
            isIE = true;
        }

        //
        //	Opções de exclusao serão incluidos com a classe noFilter
        //
        options.excluirElementos.forEach(function(item, index) {
            $(item).addClass('noFilter');
        })

        options.excluirElementosAP.forEach(function(item, index) {
            $(item).addClass('noFilterAP');
        })

        var menuContraste = obj.find("ul.pull-right li.contraste");
        var fecharMenuContraste = obj.find("ul.pull-right li.closeContrast");
        var barra = menuContraste.find("div.dautoniveis");
        var screenWidth = $(window).width();
        var zoomInBtn = obj.find(".zoomIn");
        var zoomOutBtn = obj.find(".zoomOut");

        if ( !options.tamanhoConteudo ){
            options.tamanhoConteudo = 960;
        }

        var containerX = obj.find(".container").width();
        var scale;

        if ( isFirefox ){
            $(window).resize(function() {
                containerX = obj.find(".container").width();
                screenWidth = $(window).width();
                setCookie(options.nomeCookieZoom, 1);
                zoomOut();
            });
        }

        //
        //	Verificar o cookie setado para aplicar no body
        //
        if (document.cookie.indexOf(options.nomeCookieFiltro) > 0) {
            if (getCookie(options.nomeCookieFiltro) != "normal") {
                var valueCookie = getCookie(options.nomeCookieFiltro);
                changeFilter(valueCookie);
            }
        }

        //
        //	Verificação de cookies
        //
        if (document.cookie.indexOf(options.nomeCookieZoom) < 0) {
            if (!options.zoomEscala) {
                setCookie(options.nomeCookieZoom, 0);
            } else {
                setCookie(options.nomeCookieZoom, 1);
            }
        }
        if (document.cookie.indexOf(options.nomeCookieFiltro) < 0) {
            setCookie(options.nomeCookieFiltro, 'normal');
        }

        // Cria cookie de zoom
        if (options.zoomEscala) {
            var scale = parseFloat(getCookie(options.nomeCookieZoom));
            if ( scale < 1 ) {
                setCookie(options.nomeCookieZoom, 1);
                scale = 1;
            }
        } else {
            var scale = parseFloat(getCookie(options.nomeCookieZoom));
        }

        //
        //	Clique nos níveis de contraste
        //
        barra.find("a").click(function(event) {
            var nivel = $(this).attr("data-nivel");

            //Cria o cookie com o filtro selecionado
            
            setCookie(options.nomeCookieFiltro, nivel, 0);

            //aplica o filtro chamando o metodo de aplicar filtro
            changeFilter(nivel);

            if ($(window).width() <= 992) {
                barra.removeClass('opened').addClass('closed');
            }

            if (options.urlsCookie && options.urlsCookie != undefined && options.urlsCookie != null && options.urlsCookie.length > 0){
                for (var i = 0; i < options.urlsCookie.length; i++) {
                    if(options.urlsCookie[i] && options.urlsCookie[i] != undefined && options.urlsCookie[i] != "")
                    {
                        ajaxCookieAcessibilidadeContraste(options.urlsCookie[i] + nivel);
                    }
                }
            }
            
            return false;
        });

        //
        //	Verificação contraste para IE ( Link contraste aplica e remove o contraste amarelo no preto )
        //
        if ( isIE ) {

            var btnContraste = barra.parent().find("a");
            barra.remove();

            if ( getCookie(options.nomeCookieFiltro) == "amarelopreto" ){
                btnContraste.attr("data-nivel","normal");
            }else{
                btnContraste.attr("data-nivel","amarelopreto");
            }

            // Botao Contraste aplicando filtro
            btnContraste.click(function(event) {

                var btn = $(this);
                var nivel = $(this).attr("data-nivel");
                setCookie(options.nomeCookieFiltro, nivel, 0);
                changeFilter(nivel);
                if( nivel == "amarelopreto"){
                    btn.attr("data-nivel","normal");
                }else{
                    btn.attr("data-nivel","amarelopreto");
                }
                return false;

            });

        }

        //
        //	Função aplicar filtro
        //
        function changeFilter(nivel) {
            switch (nivel) {
                // Caso default
                case 'normal':
                    resetFilter();
                    break;
                    // Caso for alto contraste
                case 'altocontraste':
                    resetFilter();
                    $("head").append('<link id="santAcess-altocontraste" rel="stylesheet" type="text/css" href="'+ options.caminhoCss + 'sa.altocontraste.css" />');
                    break;
                    // Caso for monocromático
                case 'monocromatico':
                    resetFilter();
                    $("head").append('<link id="santAcess-monocromatico" rel="stylesheet" type="text/css" href="'+ options.caminhoCss + 'sa.monocromatico.css" />');
                    break;
                    // Caso for escala de cinza
                case 'cinzainvertida':
                    resetFilter();
                    $("head").append('<link id="santAcess-cinzainvertida" rel="stylesheet" type="text/css" href="'+ options.caminhoCss + 'sa.cinzainvertida.css" />');
                    break;

                    // Caso de cores invertidas
                case 'corinvertida':
                    resetFilter();
                    $("head").append('<link id="santAcess-corinvertida" rel="stylesheet" type="text/css" href="'+ options.caminhoCss + 'sa.corinvertida.css" />');
                    break;

                    // Caso de amarelo no preto
                case 'amarelopreto':
                    resetFilter();
                    $("head").append('<link id="santAcess-amarelopreto" rel="stylesheet" type="text/css" href="'+ options.caminhoCss + 'sa.amarelopreto.css" />');
                    break;
            }
        }

        //
        //	Funcao resetar filtros
        //
        function resetFilter() {
            $('link[id^="santAcess-"]').remove();
        }

        //
        //	Adiciona a fonte base ao projeto
        //
        if (!options.zoomEscala) {
            $(options.elementosMudancaFonte).each(function() {
                var obj = $(this);
                var itemFontSize = obj.css("font-size");
                var itemFontSize = itemFontSize.replace("px", "").trim();

                var itemLineHeight = obj.css("line-height");
                var itemLineHeight = itemLineHeight.replace("px", "").trim();

                obj.attr("data-font", itemFontSize);
                obj.attr("data-lheight", itemLineHeight);
            });
        }

        //
        //	Funcao para mudar a fonte
        //
        function changeFont(zoom, porcentagem) {
            if (!porcentagem) {
                porcentagem = options.zoomPorcentagem;
            }
            $(options.elementosMudancaFonte).each(function() {
                var obj = $(this);
                var itemFontSize = obj.attr("data-font");
                var itemLineHeight = obj.attr("data-lheight");

                if (zoom == 0) {
                    var novoTamanho = itemFontSize;
                    var novoTamanhoLh = itemLineHeight;
                } else {
                    var calculo = Math.floor(parseFloat(porcentagem) * zoom) / 100;
                    var multiplicador = parseFloat(1.0) + calculo;
                    var novoTamanho = parseFloat(itemFontSize) * multiplicador;
                    var novoTamanhoLh = parseFloat(itemLineHeight) * multiplicador;
                }

                obj.css({
                    "font-size": novoTamanho + "px",
                    "line-height": novoTamanhoLh + "px"
                });
            });
        }

        //
        //	Função verifica tela
        //
        function verify(type) {
            if (type == 'in') {
                var newSize = containerX * (scale + 0.2);
                if (newSize < screenWidth) {
                    return true;
                } else {
                    return false;
                }
            }
            if (type == 'out') {
                var newSize = containerX - (containerX / (scale - 0.2));
                if (newSize > 0) {
                    return true;
                } else {
                    return false;
                }
            }
        }

        var currFFZoom = parseFloat(getCookie(options.nomeCookieZoom));
        var currIEZoom = ( parseFloat(currFFZoom) ) * 100;
        var valid = false;

        function plus(){
            var step = 0.2;
            currFFZoom += step;
            $('body').css('MozTransform','scale(' + currFFZoom + ')');
            var stepie = 20;
            currIEZoom += stepie;
            $('body').css('zoom', ' ' + currIEZoom + '%');
            $('body').width(window.innerWidth);
            setCookie(options.nomeCookieZoom, currFFZoom);
            //centralizarZoom();
        };
        function minus(){
            if ( currFFZoom > 1) {
                var step = 0.2;
                currFFZoom -= step;
                $('body').css('MozTransform','scale(' + currFFZoom + ')');
                var stepie = 20;
                currIEZoom -= stepie;
                $('body').css('zoom', ' ' + currIEZoom + '%');
                if (currFFZoom == 1) {
                    $('body').width("100%");
                }else{
                    $('body').width(window.innerWidth);
                }
                setCookie(options.nomeCookieZoom, currFFZoom);
                //centralizarZoom();
            }else{
                $('body').width("100%");
            }
        };

        //
        //	Centralizar no zoom
        //
        function centralizarZoom(){
            
            if ( $(".container").outerWidth() > $(".container").outerWidth(true) ){
                $('body').width(window.innerWidth);
            }else{
                $('body').width("100%");
            }
            var ld = $(document).width() - $('body').width();
            var nv = ld / 2;
            window.scrollTo( nv, $('body').offset().top);
        }

        //
        //	Função zoomIn em escala
        //
        function zoomIn() {
            if (verify('in') == true) {
                scale += 0.2;
                $('body').css({
                    'transform': 'scale(' + scale + ',' + scale + ')',
                    'transformOrigin': '50% 0',
                    'overflow-x': 'hidden'
                });
                setCookie(options.nomeCookieZoom, scale);
            }
        }

        //
        //	Função zoomOut em escala
        //
        function zoomOut() {
            if (verify('out') == true) {
                scale -= 0.2;
                $('body').css({
                    'transform': 'scale(' + scale + ',' + scale + ')',
                    'transformOrigin': '50% 0'
                });
            } else {
                scale = 1;
                $('body').css({
                    'transform': 'scale(' + scale + ',' + scale + ')',
                    'transformOrigin': '50% 0',
                    'overflow-x': 'auto'
                });
            }
            setCookie(options.nomeCookieZoom, scale);
        }

        //
        //	Clique para zoom
        //
        zoomInBtn.click(function () {
            if (!options.zoomEscala) {
                var zoom = parseInt(getCookie(options.nomeCookieZoom)) + 1;
                if (zoom < (options.zoomMaximo + 1)) {
                    changeFont(zoom);
                }
                if (zoom <= options.zoomMaximo) {
                    setCookie(options.nomeCookieZoom, zoom);
                }
            } else {
                if ( isFirefox ){
                    zoomIn();
                }else{
                    plus();
                }
            }
            return false;
        });

        //
        //	Clique para zoom
        //
        zoomOutBtn.click(function() {
            if (!options.zoomEscala) {
                var zoom = parseInt(getCookie(options.nomeCookieZoom)) - 1;
                if (zoom >= 0) {
                    setCookie(options.nomeCookieZoom, zoom);
                    changeFont(zoom);
                }
            } else {
                if ( isFirefox ){
                    if (scale > 1) {
                        zoomOut();
                    }
                }else{
                    minus();
                }
            }
            return false;
        });

        var cookieZoom = getCookie(options.nomeCookieZoom);
        if (!options.zoomEscala) {
            changeFont(cookieZoom);
        } else {
            if ( isFirefox ){
                if (cookieZoom == 1) {
                    $('body').css({
                        'transform': 'scale(' + cookieZoom + ',' + cookieZoom + ')',
                        'transformOrigin': '50% 0',
                        'overflow-x': 'auto'
                    });
                } else {
                    $('body').css({
                        'transform': 'scale(' + cookieZoom + ',' + cookieZoom + ')',
                        'transformOrigin': '50% 0',
                        'overflow-x': 'hidden'
                    });
                }
            }else{
                var cFF = cookieZoom;
                $('body').css('MozTransform','scale(' + cFF + ')');
                var cIE = ( parseFloat(cookieZoom) ) * 100;
                $('body').css('zoom', ' ' + ( 0.100 + cIE ) - 0.1 + '%');
                // centralizarZoom();
            }
        }

        //
        //	Verificação para hover e/ou clique para abrir menu de itens da barra
        //
        if (screenWidth > 992) {
            if (!menuContraste.find('a').hasClass('contrasteOff')) {
                menuContraste.find('a').hover(function() {
                    menuContraste.find('div.dautoniveis').removeClass('closed').addClass('opened');
                }, function() {
                    menuContraste.find('div.dautoniveis').removeClass('opened').addClass('closed');
                }).click(function() {
                    if (barra.hasClass('opened')) {
                        barra.removeClass('opened').addClass('closed');
                    } else {
                        barra.removeClass('closed').addClass('opened');
                    }
                });
            }
            fecharMenuContraste.find('a').focusin(function() {
                barra.removeClass('opened').addClass('closed');
            });
        } else {
            menuContraste.find(' > a').on('click', function() {
                if (barra.hasClass('closed')) {
                    barra.removeClass('closed').addClass('opened');
                } else {
                    barra.removeClass('opened').addClass('closed');
                }
            });
        }

        //
        //	Monta link de Livro acessível de acordo com o código de origem
        //
        var urlLivroAcessivel = $('.barraAcessivel ul li a.livroAcessivel').attr('href');
        if(urlLivroAcessivel != "#" && urlLivroAcessivel != "javascript:void(0);"){
            $('.barraAcessivel ul li a.livroAcessivel').attr('href', urlLivroAcessivel + options.codOrigem);
        }

        //
        //	Funcao Set Cookie
        //
        function setCookie(nome, valor, dias) {
            if (!dias) {
                dias = 0;
            }
            if (dias) {
                var date = new Date();
                date.setTime(date.getTime() + (dias * 24 * 60 * 60 * 1000));
                var expires = "; expires=" + date.toGMTString();
            } else var expires = "";
            document.cookie = nome + "=" + valor + expires + "; path=/";
        }

        //
        //	Funcao Get Cookie
        //
        function getCookie(cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }

        function ajaxCookieAcessibilidadeContraste(urlServico) {
            $('body').append("<img src='" + urlServico + "' style='display:none;'/>");
        }
    }); //Fim do processo


}(jQuery)




export default events;