/* eslint-disable no-undef */
import React, { Component } from 'react';
import Hosts from '../../global/Hosts';
import events from '../../script/DOMController';
import {Link} from 'react-router-dom';
import BarraAcessibilidade from '../BarraAcessibilidade';
import Idioma from '../idiomaComponent/idioma';
import Footer from '../Footer';
import LacoHeader from '../LacoHeaderComponent/LacoHeader';

export default class GamesKw extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resize: document.documentElement.clientWidth >= 1024 ? 1024 : document.documentElement.clientWidth >= 768 && document.documentElement.clientWidth < 1024 ? 768 : 640  
        };

        this.handleResize = this.handleResize.bind(this);
    }

    handleResize(){
        console.log('alterando janela')
        if (document.documentElement.clientWidth >= 1024) {
            
            this.setState({
                resize: 1024
            });
        } else if(document.documentElement.clientWidth >= 768 && document.documentElement.clientWidth < 1024) {
            this.setState({
                resize: 768
            });
        }else{
            this.setState({
                resize: 640
            });
        }
    }
    
    componentDidMount(){
        events.docReady()
        console.log('alterando janela')
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        console.log('alterando janela'+' destroy')
        document.removeEventListener('resize', this.handleResize);
    }


    render() {
        return(
            <React.Fragment>
                <div className="topo" id="inicio">
                    <BarraAcessibilidade />
                </div>
                <div id="main">
                    <div className="interno" >

                    {/* <!-- Logo --> */}
                    <section className="header">
                        <LacoHeader  selecao="pt" pagina="games/en" />
                    </section>

                    <main className="games">
                        {/* <!-- Kids' Web Games --> */}
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-10 col-xs-offset-1">
                                    <div className="kidsWebGames">
                                        <h1>KIDS' WEB GAMES</h1>
                                        <p>Aplicativo gratuito da 3ª edição da coleção <b><i>Kids' Web</i></b>, destinada aos anos iniciais do Ensino Fundamental.</p>
                                        <p>Com jogos em realidade aumentada, ambientes 360° e recurso anáglifo, o aplicativo permite que você aprenda inglês a partir de diferentes experiências digitais! Siga os passos abaixo para jogar:</p>
                                        <p>1. Acesse a loja de aplicativos do seu dispositivo e busque "<i>Kids' Web Games</i>" (ou pressione o ícone correspondente abaixo).</p>
                                        <p>2. Faça o <i>download</i> e instale o aplicativo.</p>
                                        <p>3. Pressione "Abrir" e, em seguida, o botão "<i>Scan the cover</i>".</p>
                                        <p>O aplicativo acionará a câmera do seu dispositivo. Então, basta mirar a capa de cada livro para descobrir a experiência digital que espera por você. É diversão e aprendizagem garantida dentro e fora da sala de aula!</p>
                                        <ul>
                                            <li>
                                                <a href="https://apps.apple.com/br/app/kids-web-games/id1473487558" title="Download para iOS">
                                                    <img src={Hosts['images']+"download-ios.png"} alt="Download para iOS"/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://play.google.com/store/apps/details?id=br.com.richmond.kidsweb" title="Download para Android">
                                                    <img src={Hosts['images']+"download-android.png"} alt="Download para Android"/>
                                                </a>
                                            </li>    
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Little Finder --> */}
                        <div className="kwBox branco">
                            <div className="container">
                                <div className="row">
                                    
                                    <div className="txt col-xs-12 col-sm-5 col-md-5 col-md-offset-1">
                                        <h3 className="little">Little Finder <i>(Kids' Web</i>&nbsp;&nbsp;1)</h3>
                                        <p>Jogo em realidade aumentada em que o mundo ilustrado na capa do livro ganha vida e novos elementos surgem nele.</p>
                                        <p>Seu desafio será encontrar os elementos indicados. Para isso, gire a imagem e explore cada cantinho do mundo do Webby e sua turminha.</p>
                                        <p>Embarque você também em uma experiência digital dentro do fascinante mundo de <b><i>Kids' Web</i></b>!</p>
                                    </div>
                                    <div className="images littleBox col-xs-12 col-sm-7 col-md-6">
                                        { this.state.resize === 1024 && <img className="little img1024" src={Hosts['images']+"banner-LITTLE-FINDER.png"} alt="LITTLE FINDER"/> }
                                        { this.state.resize === 768 &&  <img className="little img768" src={Hosts['images']+"little-finder-768-cortado.png"} alt="LITTLE FINDER"/> }
                                        { this.state.resize === 640 &&  <img className="little img640" src={Hosts['images']+"little-finder-640.png"} alt="LITTLE FINDER"/> }
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        {/* <!-- Magic Animals --> */}
                        <div className="kwBox">
                            <div className="container">
                                <div className="row">
                                    
                                    <div className="txt magicAnimalsBox col-xs-12 col-sm-6 col-md-7">
                                        <h3 className="col-xs-12 col-md-10">Magic Animals <i>(Kids' Web</i>&nbsp;&nbsp;2)</h3>
                                        <p  className="col-xs-12 col-md-10">Jogo anáglifo em que a girafa ilustrada na capa do livro leva você para uma aventura selvagem!</p>
                                        <p  className="col-xs-12 col-md-10">Seu desafio será contar os animais da espécie indicada usando os óculos que acompanham seu livro. Feche um olho de cada vez para descobrir com que lente você consegue vê-los: com a azul ou com a vermelha. Fique de olho no cronômetro e veja se você acertou a contagem!</p>
                                        
                                        <p  className="col-xs-12 col-md-10">Aventure-se nessa experiência digital e animal!</p>
                                    </div>
                                    <div className="images magicAnimalsBox col-xs-12 col-sm-6 col-md-5">
                                        <img className="magicAnimals" src={Hosts['images']+"banner-MAGIC-ANIMALS.png"} alt="MAGIC ANIMALS"/>
                                    </div>

                                </div>                            
                            </div>
                        </div>
                        {/* <!-- Home Sweet Home --> */}
                        <div className="kwBox branco">
                            <div className="container">
                                <div className="row">

                                    <div className="txt col-xs-12 col-sm-5 col-md-5 col-md-offset-1">
                                        <h3>Home Sweet Home <i>(Kids' Web</i>&nbsp;&nbsp;3)</h3>
                                        <p>Jogo em ambiente 360° em que você será transportado à casa da menina sentada à mesa de estudos, ilustrada na capa. Nessa casa, há alguns problemas que podem atrapalhar a convivência.</p>
                                        <p>Seu desafio será explorar cada cômodo movimentando seu aparelho, a fim de encontrar a bagunça e arrumá-la.</p>
                                        <p>Seja bem-vindo(a) a essa casa e deixe-a em ordem, vivenciando uma experiência digital e investigativa!</p>
                                    </div>
                                    <div className="images col-xs-12 col-sm-6 ">
                                        { this.state.resize === 1024 && <img className="sweetHome img1024" src={Hosts['images']+"banner-HOME-SWEET-HOME.png"} alt="HOME SWEET HOME"/> }
                                        { this.state.resize === 768 && <img className="sweetHome img768" src={Hosts['images']+"home-768.png"} alt="HOME SWEET HOME"/> }
                                        { this.state.resize === 640 && <img className="sweetHome img640" src={Hosts['images']+"home-640.png"} alt="HOME SWEET HOME"/> }
                                    </div>     

                                </div>
                            </div>
                        </div>
                        {/* <!-- The Egg's Journey --> */}
                        <div className="kwBox eggsJourneyBox">
                            <div className="container">
                                <div className="row">
                                    
                                    <div className="txt txtEggsJourneyBox col-xs-12 col-sm-6 col-md-7">
                                        <h3 className="col-xs-12 col-md-10">The Egg’s Journey <i>(Kids' Web</i>&nbsp;&nbsp;4)</h3>
                                        <p className="col-xs-12 col-md-10">Jogo em ambiente 360° em que você vai conhecer a história do ovo, ilustrado na capa do livro. Ele caiu do galinheiro e precisa encontrar sua mãe.</p>
                                        <p className="col-xs-12 col-md-10">Seu desafio é acompanhá-lo, movendo seu dispositivo para
                                        ajudá-lo a vencer os obstáculos ao longo da jornada em busca de sua mãe. Será que ele conseguirá encontrá-la?</p>
                                        <p className="col-xs-12 col-md-10">Faça essa jornada em busca da mamãe galinha e da amorosa família que esperam pelo ovo perdido!</p>
                                    </div>
                                    <div className="images imageEggsJourneyBox  col-xs-12 col-sm-6 col-md-5">
                                        { this.state.resize > 640 && <img className="eggsJourney img1024" src={Hosts['images']+"banner-THE-EGGS-JOURNEY.png"} alt="THE EGGS JOURNEY"/> }
                                        { this.state.resize <= 640 && <img className="eggsJourney img640" src={Hosts['images']+"eggs-journey-640.png"} alt="THE EGGS JOURNEY"/> }
                                    </div> 
                                    
                                </div>
                            </div>
                        </div>
                        {/* <!-- World Explorer --> */}
                        <div className="kwBox branco worldExplorerBox">
                            <div className="container">
                                <div className="row">
                                   
                                    <div className="txt col-xs-12 col-sm-5 col-md-5 col-md-offset-1">
                                        <h3>World Explorer (<i>Kids' Web</i>&nbsp;&nbsp;5)</h3>
                                        <p>Jogo em realidade aumentada em que o mundo ilustrado na capa do livro se transforma em um globo terrestre gamificado.</p>
                                        <p>Você vai ouvir uma sequência de nomes de países, que cresce conforme o jogo evolui.</p>
                                        <p>Seu desafio será localizar os países no globo seguindo a mesma sequência apresentada. A cada sequência correta, você aprende mais sobre um dos países.</p>
                                        <p>Embarque nessa viagem, teste sua memória e vivencie uma experiência digital e exploradora!</p>
                                    </div>
                                    <div className="images col-xs-12 col-sm-6">
                                    { this.state.resize === 1024 && <img className="worldExplorer img1024" src={Hosts['images']+"banner-WORLD-EXPLORER.png"} alt="WORLD EXPLORER"/> }
                                    { this.state.resize === 768 && <img className="worldExplorer img768" src={Hosts['images']+"world-explorer-768.png"} alt="WORLD EXPLORER"/> }
                                    { this.state.resize === 640 && <img className="worldExplorer img640" src={Hosts['images']+"world-explorer-640.png"} alt="WORLD EXPLORER"/> }
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                        {/* <!-- Perguntas Frequentes --> */}
                        <div className="container">
                            <div className="perguntasFrequentes">
                                <div className="row">
                                    <div className="col-xs-12 col-md-10 col-md-offset-1">
                                        <h1>Perguntas Frequentes</h1>
                                        
                                        <div className="conteudoCollapse">
                                            <a data-toggle="collapse" href="#collapse1" aria-expanded="false" aria-controls="collapseExample">
                                                1. Em que dispositivos posso baixar o <i>Kids' Web Games</i>?
                                            </a>
                                            <div className="collapse pleft" id="collapse1">
                                                <p>Em <i>tablets</i> ou <i>smartphones</i> com sistema operacional Android ou iOS. Não há versão <i>web</i> nem <i>desktop</i>.</p>
                                            </div>
                                        </div>

                                        <div className="conteudoCollapse">
                                            <a data-toggle="collapse" href="#collapse2" aria-expanded="false" aria-controls="collapseExample">
                                                2. Quais são os requisitos mínimos que o dispositivo deve ter?
                                            </a>
                                            <div className="collapse" id="collapse2">
                                                <ul>
                                                    <li>Sistema operacional Android 6 ou superior.</li>
                                                    <li>Sistema operacional iOS versão 11 ou superior.</li>
                                                    <li>Conexão com a Internet, apenas para o <i>download</i> do aplicativo. Uma vez instalado, é possível utilizá-lo <i>off-line</i>.</li>
                                                    <li>Câmera de menos de 3 <i>Megapixels</i>, com autofoco.</li>
                                                    <li>Giroscópio (necessário para melhor experiência dos jogos <i>Home Sweet Home</i> e <i>The Egg's Journey</i>).</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="conteudoCollapse">
                                            <a data-toggle="collapse" href="#collapse3" aria-expanded="false" aria-controls="collapseExample">
                                                3. Como faço o <i>download</i> do <i>Kids' Web Games</i>?
                                            </a>
                                            <div className="collapse pleft" id="collapse3">
                                                <p>Acesse a loja de aplicativos de seu dispositivo. Se preferir, clique no ícone indicado acima, correspondente ao sistema operacional do seu dispositivo, para ser redirecionado à loja de aplicativos e fazer o <i>download</i>.</p>
                                            </div>
                                        </div>

                                        <div className="conteudoCollapse">
                                            <a data-toggle="collapse" href="#collapse4" aria-expanded="false" aria-controls="collapseExample">
                                                4. Preciso de cadastro ou de algum código para usar o aplicativo?
                                            </a>
                                            <div className="collapse pleft" id="collapse4">
                                                <p>Não é necessário nenhum cadastro ou código de acesso. Você só precisa ter os livros da coleção <b><i>Kids' Web</i></b>, pois após a instalação do aplicativo deverá pressionar "<i>Scan the cover</i>" e apontar a câmera de seu dispositivo para a capa do livro. O jogo correspondente àquela capa será ativado automaticamente.</p>
                                            </div>
                                        </div>

                                        <div className="conteudoCollapse">
                                            <a data-toggle="collapse" href="#collapse5" aria-expanded="false" aria-controls="collapseExample">
                                                5. Após instalar o aplicativo, como eu faço para jogar?
                                            </a>
                                            <div className="collapse pleft" id="collapse5">
                                                <p>Para jogar cada jogo pela primeira vez é preciso ativá-lo. Para isso:</p>
                                                <ol>
                                                    <li>Abra o aplicativo.</li>
                                                    <li>Pressione o botão "<i>Scan the cover</i>", e a câmera de seu aparelho será ativada.</li>
                                                    <li>Aponte a câmera de seu aparelho para a capa do seu livro.</li>
                                                    <li>Pronto! Seu jogo está ativado.</li>
                                                    <li>Pressione o botão "<i>Play</i>" e comece a jogar.</li>
                                                    <li>Se desejar, pressione o botão de interrogação, no canto superior direito da tela, para assistir a um tutorial sobre como jogar.</li>
                                                </ol>
                                            </div>
                                        </div>

                                        <div className="conteudoCollapse">
                                            <a data-toggle="collapse" href="#collapse6" aria-expanded="false" aria-controls="collapseExample">
                                                6. Como eu posso jogar novamente após sair do aplicativo?
                                            </a>
                                            <div className="collapse pleft" id="collapse6">
                                                <p>Como você já ativou o jogo, para jogar novamente:</p>
                                                <ol>
                                                    <li>Abra o aplicativo.</li>
                                                    <li>Pressione o botão "<i>My games</i>".</li>
                                                    <li>Selecione o jogo que você já ativou e que deseja jogar.</li>
                                                    <li>Pressione o botão "<i>Play</i>" para começar a jogar.</li>
                                                </ol>
                                            </div>
                                        </div>

                                        <div className="conteudoCollapse">
                                            <a data-toggle="collapse" href="#collapse7" aria-expanded="false" aria-controls="collapseExample">
                                                7. Meu dispositivo não está escaneando a capa. O que posso fazer?
                                            </a>
                                            <div className="collapse pleft" id="collapse7">
                                                <p>A leitura das imagens da capa deve ser feita em um ambiente bem iluminado. Tente escanear a capa novamente em um local mais iluminado. Caso o problema persista, procure um técnico e verifique se a câmera de seu dispositivo está com algum problema.</p>
                                            </div>
                                        </div>

                                        <div className="conteudoCollapse">
                                            <a data-toggle="collapse" href="#collapse8" aria-expanded="false" aria-controls="collapseExample">
                                                8. Ao pressionar o botão "<i>Scan the cover</i>", aparece uma tela de erro. O que devo fazer?
                                            </a>
                                            <div className="collapse pleft" id="collapse8">
                                                <p>Feche o aplicativo e abra-o novamente. Uma mensagem de permissão para tirar fotos ou gravar vídeos será exibida. Escolha a opção para permitir. Caso o problema persista, procure um técnico e verifique se a câmera de seu dispositivo está com algum problema.</p>
                                            </div>
                                        </div>

                                        <div className="conteudoCollapse">
                                            <a data-toggle="collapse" href="#collapse9" aria-expanded="false" aria-controls="collapseExample">
                                                9. Por que na área "<i>My games</i>" há jogos com cadeado?
                                            </a>
                                            <div className="collapse pleft" id="collapse9">
                                                <p>Os cadeados nos jogos indicam que eles ainda não foram ativados. Para ativá-los, você precisa ter a capa do livro de cada volume da coleção <b><i>Kids' Web</i></b>:</p>
                                                <ul>
                                                    <li><i>Little Finder</i> (<i>Kids' Web</i>  1) </li>
                                                    <li><i>Magic Animals </i>(<i>Kids' Web</i>  2)</li>
                                                    <li><i>Home Sweet Home </i>(<i>Kids' Web</i>  3)</li>
                                                    <li><i>The Egg's Journey </i>(<i>Kids' Web</i>  4)</li>
                                                    <li><i>World Explorer </i>(<i>Kids' Web</i>  5) </li>
                                                </ul>
                                                <p>Assim que tiver a capa, pressione o botão "<i>Scan the cover</i>" ou o jogo com cadeado que deseja ativar, na área "<i>My games</i>", e siga os passos indicados na resposta 4.</p>
                                            </div>
                                        </div>

                                        <div className="conteudoCollapse">
                                            <a data-toggle="collapse" href="#collapse10" aria-expanded="false" aria-controls="collapseExample">
                                                10. Eu não sei como jogar. O que eu faço?
                                            </a>
                                            <div className="collapse pleft" id="collapse10">
                                                <p>Na tela inicial de cada jogo e também durante os jogos, há um botão com o símbolo de "?" no canto superior direito da tela. Pressione esse botão e assista a um tutorial sobre como jogar.</p>
                                            </div>
                                        </div>

                                        <div className="conteudoCollapse">
                                            <a data-toggle="collapse" href="#collapse11" aria-expanded="false" aria-controls="collapseExample">
                                                11. Preciso do livro para poder jogar?
                                            </a>
                                            <div className="collapse pleft" id="collapse11">
                                                <p>Você precisa do livro apenas para a primeira vez que jogar, pois cada jogo é ativado pjla imagem das capas. Não é preciso consultar seu livro para conseguir jogar.</p>
                                            </div>
                                        </div>

                                        <div className="conteudoCollapse">
                                            <a data-toggle="collapse" href="#collapse12" aria-expanded="false" aria-controls="collapseExample">
                                                12. Como volto para a tela inicial do aplicativo quando estiver jogando?
                                            </a>
                                            <div className="collapse pleft" id="collapse12">
                                                <p>Em todos os jogos há um botão com o ícone de uma casa, no canto superior esquerdo da tela. Pressione-o e volte para a tela inicial do aplicativo.</p>
                                            </div>
                                        </div>

                                        <div className="conteudoCollapse">
                                            <a data-toggle="collapse" href="#collapse13" aria-expanded="false" aria-controls="collapseExample">
                                                13. Por que eu não consigo ver os ambientes dos jogos <i>Home Sweet Home</i> e <i>The Egg's Journey</i> movimentando meu celular?
                                            </a>
                                            <div className="collapse pleft" id="collapse13">
                                                <p>Isso acontece em aparelhos que não possuem o recurso de giroscópio. Caso seu aparelho não possua esse recurso, você pode movimentar-se pelo cenário deslizando o dedo na tela de seu aparelho.</p>
                                            </div>
                                        </div>

                                        <div className="conteudoCollapse">
                                            <a data-toggle="collapse" href="#collapse14" aria-expanded="false" aria-controls="collapseExample">
                                                14. Como faço para fechar o aplicativo?
                                            </a>
                                            <div className="collapse pleft" id="collapse14">
                                                <p>Siga as orientações de acordo com o sistema operacional de seu aparelho:</p>
                                                <p>Android:</p>
                                                <ol>
                                                    <li>Acesse o botão multitarefa.</li>
                                                    <li>Arraste o aplicativo para a direita ou para cima para fechá-lo (dependendo do funcionamento padrão para fechar aplicativos da versão do seu Android).</li>
                                                </ol>
                                                <p>iPhone (versões anteriores ao X):</p>
                                                <ol>
                                                    <li>Pressione duas vezes o botão "<i>Home</i>".</li>
                                                    <li>Arraste o aplicativo para cima para fechá-lo.</li>
                                                </ol>
                                                <p>iPhone X e superiores:</p>
                                                <ol>
                                                    <li>Acesse a tela inicial do aplicativo.</li>
                                                    <li>Deslize o dedo de baixo para cima, até chegar ao meio da tela.</li>
                                                    <li>Arraste o aplicativo para cima para fechá-lo.</li>
                                                </ol>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    <Footer seta={true} idioma="pt"/> 
                    </main>

                    </div>
                </div>
            </React.Fragment>
        );
  }
}
