import React from 'react'
import Hosts from '../global/Hosts'
export default class NaoAutorizado extends React.Component{
    componentDidMount(){
       sessionStorage.setItem('notallowed',true);         
       window.location=Hosts['homeNotAllowed'];
    }

    render(){
        return(<div></div>)
    }
}