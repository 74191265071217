import React from 'react';
import Idioma from '../idiomaComponent/idioma';
import Hosts from '../../global/Hosts'
import { Link } from 'react-router-dom'

export default function LacoHeader (props) { 
    return (
        <div className="laco">
            <div className="container">
                <div class="divVoltar">
                    <Link class="voltarHome" to={props.selecao === 'en' ? Hosts['localhost']+'en' : Hosts['localhost']} title={props.selecao === 'en' ? "Go Back" : "Voltar"}></Link>
                </div>
                <div className="col-xs-12">
                    {props.selecao === 'en'? <Idioma  idioma={"en"} url={props.pagina} professorValid={props.professor} /> : <Idioma  idioma={"pt"} url={props.pagina} professorValid={props.professor} />}   
                    
                    
                </div>
                {
                    props.selecao === 'en' ?
                        <Link to={Hosts["localhost"]+'en'} title="Acessar site Kids' Web : link interno" aria-label="Acessar site Kids' Web : link interno"><img className="img-fluid" src={Hosts['images']+"logo.png"} alt="Kids' Web"/></Link>:
                            <Link to={Hosts["localhost"]} title="Acessar site Kids' Web : link interno" aria-label="Acessar site Kids' Web : link interno"><img className="img-fluid" src={Hosts['images']+"logo.png"} alt="Kids' Web"/></Link>
                    }
            </div>
        </div>
    )
}