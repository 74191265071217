import React, {Component} from 'react'
import OwlCarousel from 'react-owl-carousel2'
import Hosts from '../global/Hosts'

class CarousselDestaques extends Component{

    options = {
        loop:true,
        margin:25,
        nav:true,
        dots:false,
        center: true,
        navText: ["<img src='"+Hosts['images']+"/btLeft.png' alt='botão anterior lista'>","<img src='"+Hosts['images']+"/btRight.png' alt='botão próximo lista'>"],
        responsive:{
          0:{
            items:1
          },
          600:{
              items:1
          },
          1000:{
              items:3
          }
        }
    }

    render(){
        return(
            <div className="row carousselDestaques">
    
                <div className="container">

                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <OwlCarousel options = {this.options}>
                            <div className="item">
                                <a title="CLIL and Life Skills" id="clil">
                                    <div className="col-xs-12 col-sm-12 col-md-6 LivroImg">
                                        <img className="img-fluid img-mobile" src={Hosts['images']+"img_clil.png"} alt="CLIL and Life Skills"/>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                        <p className="font">CLIL AND LIFE SKILLS</p>
                                        <p>
                                            Ao final das unidades ímpares, a obra apresenta uma página dupla
                                            que traz conteúdo de <b>CLIL</b> - com trabalho interdisciplinar sempre alinhado com
                                            as
                                            diretrizes da BNCC - ou de <b>Life Skills</b>, cujo objetivo é fazer os alunos
                                            usarem o inglês para refletir sobre as situações relevantes da sua realidade e
                                            desenvolver
                                            competências socioemocionais e cognitivas.
                                        </p>
                                    </div>                                    
                                </a>
                            </div>

                            <div className="item">
                                <a title="Glossário Interativo" id="glossario">
                                    <div className="col-xs-12 col-sm-12 col-md-6 LivroImg">
                                        <img className="img-fluid img-mobile" src={Hosts['images']+"glossario.png"} alt="Glossário Interativo"/>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                        <p className="font">GLOSSÁRIO INTERATIVO</p>
                                        <p>
                                            O tradicional glossário bilíngue passa a ser
                                            interativo nos volumes de 1 a 3. O aluno 
                                            interage com o glossário de maneira lúdica, 
                                            desenhando e completando imagens e 
                                            palavras, tudo para construir seu próprio 
                                            repertório.
                                        </p>
                                    </div>
                                </a>
                            </div>
                            
                            <div className="item">
                                <a title="Alinhado com a BNCC" id="bncc">
                                    <div className="col-xs-12 col-sm-12 col-md-6 LivroImg">
                                        <img className="img-fluid img-bncc" src={Hosts['images']+"bncc.png"} alt="Alinhamento à BNCC"/>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                        <p className="font">ALINHAMENTO COM A BNCC</p>
                                        <p>
                                            A coleção <b><i>Kids' Web 3rd edition</i></b> está 
                                            inteiramente pensada e produzida a partir das 
                                            competências e habilidades descritas na Base
                                            Nacional Comum Curricular (BNCC), de seus
                                            pilares fundamentais até seções e atividades 
                                            específicas dentro das unidades.
                                        </p>
                                    </div>
                                </a>
                            </div>
                            
                            <div className="item">
                                <a title="Booklet para a Família" id="booklet">
                                    <div className="col-xs-12 col-sm-12 col-md-6 LivroImg">
                                        <img className="img-fluid img-mobile" src={Hosts['images']+"family.png"} alt="Booklet para a Família"/>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                        <p className="font">BOOKLET PARA A FAMÍLIA</p>
                                        <p>
                                            Impresso para os volumes 1 e 2, e digital para 
                                            os demais volumes, o <i>booklet</i> para a família 
                                            tem o objetivo de auxiliar pais e responsáveis 
                                            no processo de aprendizagem de uma
                                            segunda língua.
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </OwlCarousel>
                        
                        

                    </div>

                </div>

            </div>
        )
    }
}

export default CarousselDestaques