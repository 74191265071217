import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';

import * as serviceWorker from './serviceWorker';
import Routes from './routes/routes'
import TagManager from 'react-gtm-module'


const tagManagerArgs = {
    gtmId: 'GTM-M6K86M3'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
    <Routes/>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
