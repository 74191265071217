import React from 'react'
import Hosts from '../global/Hosts'
class Footer extends React.Component{
    
   render(){
    let rows = []
    if(this.props.seta){
        rows.push(
        <div className="seta" key ={'seta'}>
            <a href="#inicio" className="fim" title={this.props.idioma === "en" ? "Initiation" : "Início"}><i className="fas fa-angle-up"></i></a>
        </div>
        )
    }
    return (
        <footer id="footer" className="container-fluid">
        <div className="row">
            <div className="col-md-12">
                <div className="container">
                    {rows}
                    <a href="https://www.richmond.com.br/portal-richmond.htm" title="Portal Richmond"><img src={Hosts['images']+"logo_richmond.png"} alt="Portal Richmond"/></a>
                </div>
            </div>
        </div>
        </footer>
   );
}
}

export default Footer;