import React from 'react'
import BarraAcessibilidade from '../BarraAcessibilidade'
import Hosts from '../../global/Hosts'
import {Link} from 'react-router-dom'
import Footer from '../Footer'
import events from '../../script/DOMController'
import axios from 'axios'
import {Redirect} from 'react-router-dom'
import Utils from '../../components/Utils'
import LacoHeader from '../LacoHeaderComponent/LacoHeader'
export default class MainProfessor extends React.Component{
    
    constructor(props){
        super(props);
        this.state={
            redirect:false,
            idAutorizado:Utils.getCookie('lumUserId')
        }
    }

    logout(){
        console.log('tá chegando aqui')
        localStorage.clear()
        window.location = Hosts['Logout'];
        // axios.get(Hosts['Logout']).finally(()=> window.location = Hosts['localhost']+'kidsweb')
    }

    componentDidMount(){
        events.docReady()

    }
    render(){
        return(
            <React.Fragment>
                <div className="topo" id="inicio">
                    <BarraAcessibilidade/>
                </div>

                <div className="mainProfessor" id='main'>

                    <div class="interno">
                        {/* <!-- Logo --> */}
                        <section className="header">
                            <LacoHeader selecao="en" pagina="area-do-professor" professor={true} />
                        </section>

                        <main className="areaDoProfessor">
                            <div className="container">
                                {/* <!-- Área do Professor --> */}
                                <div className="row">
                                    <div className="col-xs-10 col-xs-offset-1">
                                        <div className="txtAreaDoProfessor">
                                            <h1>Teacher's Area</h1>
                                            <p>In this area you will find downloadable materials so that you can store them on your devices and use them offline by projecting or playing them during class.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Flashcards --> */}
                                <div className="row">
                                    <div className="col-xs-12 col-sm-10 col-sm-offset-1 col-md-6 col-md-offset-3 ">
                                    <a href={Hosts['files']+"flashcards/Flashcards.zip"} title="Flashcards" download="flashcards">
                                        <div className="flashcards">
                                            <h2>Flashcards</h2>
                                            <p>148 flashcards that present the main vocabulary items worked in the series.</p>
                                            <a href={Hosts['files']+"flashcards/Flashcards.zip"} title="Flashcards" download="flashcards"><img className="flashcardsImage" src={Hosts['images']+"fotos_area-do-professor.png"} alt="Fotos de flashcards" /></a>
                                        </div>
                                    </a>
                                    </div>
                                </div>
                                {/* <!-- Pôsteres --> */}
                                <div className="row">
                                    <div className="col-xs-10 col-xs-offset-1 col-md-8 col-md-offset-2">
                                        <div className="posteres">
                                            <h2>Posters</h2>
                                            <p>The posters (one for each book) offer a review of the contents explored throughout the year, except for the poster of book 1, which reinforces the identification of Webby and his gang.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Volumes --> */}
                                <div className="row">
                                    <div className="col-xs-12 col-md-10 col-md-offset-1">   
                                        <div className="volumes desktop">
                                        <a href={Hosts['files']+"posters/poster_3kw1_m.pdf"} title="Poster – Book 1" download="poster-volume-1">
                                        <img src={Hosts["images"]+"/poster-book-1.png"} alt="Volume 1" />
                                    </a>
                                    <a href={Hosts['files']+"posters/poster_3kw2_m.pdf"} title="Poster – Book 2" download="poster-volume-2">
                                        <img src={Hosts["images"]+"/poster-book-2.png"} alt="Volume 2" />
                                    </a>
                                    <a href={Hosts['files']+"posters/poster_3kw3_m.pdf"} title="Poster – Book 3" download="poster-volume-3">
                                        <img src={Hosts["images"]+"/poster-book-3.png"} alt="Volume 3" />
                                    </a>
                                    <a href={Hosts['files']+"posters/poster_3kw4_m.pdf"} title="Poster – Book 4" download="poster-volume-4">
                                        <img src={Hosts["images"]+"/poster-book-4.png"} alt="Volume 4" />
                                    </a>
                                    <a href={Hosts['files']+"posters/poster_3kw5_m.pdf"} title="Poster – Book 5" download="poster-volume-5">
                                        <img src={Hosts["images"]+"/poster-book-5.png"} alt="Volume 5" />   
                                    </a>
                                        </div>
                                        <div className="volumes mobile">
                                        <a href={Hosts['files']+"posters/poster_3kw1_m.pdf"} title="Poster – Book 1" download="poster-volume-1">
                                        <img src={Hosts["images"]+"/poster-book-1.png"} alt="Volume 1" />
                                    </a>
                                    <a href={Hosts['files']+"posters/poster_3kw2_m.pdf"} title="Poster – Book 2" download="poster-volume-2">
                                        <img src={Hosts["images"]+"/poster-book-2.png"} alt="Volume 2" />
                                    </a>
                                    <a href={Hosts['files']+"posters/poster_3kw3_m.pdf"} title="Poster – Book 3" download="poster-volume-3">
                                        <img src={Hosts["images"]+"/poster-book-3.png"} alt="Volume 3" />
                                    </a>
                                    <a href={Hosts['files']+"posters/poster_3kw4_m.pdf"} title="Poster – Book 4" download="poster-volume-4">
                                        <img src={Hosts["images"]+"/poster-book-4.png"} alt="Volume 4" />
                                    </a>
                                    <a href={Hosts['files']+"posters/poster_3kw5_m.pdf"} title="Poster – Book 5" download="poster-volume-5">                                
                                        <img src={Hosts["images"]+"/poster-book-5.png"} alt="Volume 5" />
                                    </a>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Livro Digital para Projeção --> */}
                                <div className="row">
                                    <div className="col-xs-10 col-xs-offset-1 col-md-8 col-md-offset-2">
                                        <div className="livroDigital">
                                            <h2>Digital Book (IWB)</h2>
                                            <p>It provides integrated audios and has the same format as the printed book, so that it can be shown by projectors or on interactive whiteboards.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Volume Livros --> */}
                                <div className="row">
                                    <div className="col-xs-12 col-md-10 col-md-offset-1">   
                                        <div className="livrosVolumes desktop">
                                    <a href={Hosts['files']+"livroprojecao/volume1.zip"} title="Digital Book (IWB) – Book 1" download="livro-volume-1">
                                        <img src={Hosts["images"]+"/livro-book-1.png"} alt="Digital book (IWB) – Book 1" />
                                    </a>
                                    <a href={Hosts['files']+"livroprojecao/volume2.zip"} title="Digital Book (IWB) – Book 2" download="livro-volume-2">
                                        <img src={Hosts["images"]+"/livro-book-2.png"} alt="Digital book (IWB) – Book 2" />
                                    </a>
                                    <a href={Hosts['files']+"livroprojecao/volume3.zip"} title="Digital Book (IWB) – Book 3" download="livro-volume-3">
                                        <img src={Hosts["images"]+"/livro-book-3.png"} alt="Digital book (IWB) – Book 3" />
                                    </a>
                                    <a href={Hosts['files']+"livroprojecao/volume4.zip"} title="Digital Book (IWB) – Book 4" download="livro-volume-4">
                                        <img src={Hosts["images"]+"/livro-book-4.png"} alt="Digital book (IWB) – Book 4" />
                                    </a>
                                    <a href={Hosts['files']+"livroprojecao/volume5.zip"} title="Digital Book (IWB) – Book 5" download="livro-volume-5">
                                        <img src={Hosts["images"]+"/livro-book-5.png"} alt="Digital book (IWB) – Book 5" />
                                    </a>
                                        </div>
                                        <div className="livrosVolumes mobile">
                                    <a href={Hosts['files']+"livroprojecao/volume1.zip"} title="Digital Book (IWB) – Book 1" download="livro-volume-1">
                                        <img src={Hosts["images"]+"/livro-book-1.png"} alt="Digital book (IWB) – Book 1" />
                                    </a>
                                    <a href={Hosts['files']+"livroprojecao/volume2.zip"} title="Digital Book (IWB) – Book 2" download="livro-volume-2">
                                        <img src={Hosts["images"]+"/livro-book-2.png"} alt="Digital book (IWB) – Book 2" />
                                    </a>
                                    <a href={Hosts['files']+"livroprojecao/volume3.zip"} title="Digital Book (IWB) – Book 3" download="livro-volume-3">
                                        <img src={Hosts["images"]+"/livro-book-3.png"} alt="Digital book (IWB) – Book 3" />
                                    </a>
                                    <a href={Hosts['files']+"livroprojecao/volume4.zip"} title="Digital Book (IWB) – Book 4" download="livro-volume-4">
                                        <img src={Hosts["images"]+"/livro-book-4.png"} alt="Digital book (IWB) – Book 4" />
                                    </a>
                                    <a href={Hosts['files']+"livroprojecao/volume1.zip"} title="Digital Book (IWB) – Book 5" download="livro-volume-5">
                                        <img src={Hosts["images"]+"/livro-book-5.png"} alt="Digital book (IWB) – Book 5" />
                                    </a>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Áudio --> */}
                                <div className="row">
                                    <div className="col-xs-12 col-md-8 col-md-offset-2">
                                        <div className="audio">
                                            <h2>Audios</h2>
                                            <p>In this area you will find the audios and songs of the activities from all the books of the collection.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Volume Áudios --> */}
                                <div className="row">
                                    <div className="col-xs-12 col-md-10 col-md-offset-1">   
                                        <div className="audioVolumes desktop">
                                            <a href={Hosts['files']+"audios/Volume1.zip"} title="Audios – Book 1" download="audio-volume-1"><img src={Hosts['images']+"audio-book-1.png"} alt="Audios – Volume 1" /></a>
                                            <a href={Hosts['files']+"audios/Volume2.zip"} title="Audios – Book 2" download="audio-volume-2"><img src={Hosts['images']+"audio-book-2.png"} alt="Audios – Volume 2" /></a>
                                            <a href={Hosts['files']+"audios/Volume3.zip"} title="Audios – Book 3" download="audio-volume-3"><img src={Hosts['images']+"audio-book-3.png"} alt="Audios – Volume 3" /></a>
                                            <a href={Hosts['files']+"audios/Volume4.zip"} title="Audios – Book 4" download="audio-volume-4"><img src={Hosts['images']+"audio-book-4.png"} alt="Audios – Volume 4" /></a>
                                            <a href={Hosts['files']+"audios/Volume5.zip"} title="Audios – Book 5" download="audio-volume-5"><img src={Hosts['images']+"audio-book-5.png"} alt="Audios – Volume 5" /></a>
                                        </div>
                                        <div className="audioVolumes mobile">
                                            <a href={Hosts['files']+"audios/Volume1.zip"} title="Audios – Volume 1" download="audio-volume-1"><img src={Hosts['images']+"audio-book-1.png"} alt="Audios – Volume 1" /></a>
                                            <a href={Hosts['files']+"audios/Volume2.zip"} title="Audios – Volume 2" download="audio-volume-2"><img src={Hosts['images']+"audio-book-2.png"} alt="Audios – Volume 2" /></a>
                                            <a href={Hosts['files']+"audios/Volume3.zip"} title="Audios – Volume 3" download="audio-volume-3"><img src={Hosts['images']+"audio-book-3.png"} alt="Audios – Volume 3" /></a>
                                            <a href={Hosts['files']+"audios/Volume4.zip"} title="Audios – Volume 4" download="audio-volume-4"><img src={Hosts['images']+"audio-book-4.png"} alt="Audios – Volume 4" /></a>
                                            <a href={Hosts['files']+"audios/Volume5.zip"} title="Audios – Volume 5" download="audio-volume-5"><img src={Hosts['images']+"audio-book-5.png"} alt="Audios – Volume 5" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>

                    </div>

                    <Footer seta={true} idioma="en"/>

                </div>
            </React.Fragment>
        )
    }
}