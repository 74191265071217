import React from 'react'
import BarraAcessibilidade from './BarraAcessibilidade'
import Hosts from '../global/Hosts'
import Player from './Player'
import { Link } from 'react-router-dom'
import Footer from './Footer'
import events from '../script/DOMController'
import LacoHeader from './LacoHeaderComponent/LacoHeader'




export default class EmocionaisDemo extends React.Component {

    constructor(props){
        super(props);
        this.state={
            video: '/TeaserOppaKeki.mp4',
            listaVideos: {
                0: 'TeaserOppaKeki.mp4',
                1: 'baby-dory-clip---finding-dory.mp4',
                // player3: 'TeaserOppaKeki.mp4',
                // player4: 'TeaserOppaKeki.mp4',
            }
        }
    }

    videoconfig = {
        autoplay: false,
        language:'pt',
        controls: true,
        width: '100%',
        height: '100%',
        thumb: 'thumb-video-demo-socioemocionais.png',
        sources: [{
            src: Hosts['videos'] + 'TeaserOppaKeki.mp4',
            type: 'video/mp4'
        }]
    }

    componentDidMount(){
        events.docReady()
    }
    render() {
        return (
            <div className="gamesdemo">
                <div class="topo" id="inicio">
                    <BarraAcessibilidade />
                </div>
                <div id='main'>
                
                <div class="interno socioemocionais">

                    {/* <!-- Logo --> */}
                    <section class="header">
                        <LacoHeader  selecao="pt" pagina="emocionaisdemo/en" />
                    </section>

                    <div class="conteudo">
                        
                        <div class="container">
                            <div class="boxdescricao socio">
                                <h1>DEMO</h1>
                                <h3>Animações socioemocionais</h3>
                                <p>Cinco episódios da série <b><i>Oppa Kêki</i></b>, que narram as aventuras cotidianas de um cágado e um tatu fêmea na Amazônia. A partir das experiências que vivenciam, é possível trabalhar a língua inglesa associada à reflexão sobre diferentes habilidades socioemocionais.</p>
                            </div>
                            
                            <div class="row iframeInterno socio">
                                <div class="col-xs-12 col-sm-12 col-md-12">
                         

                                <Player options = {this.videoconfig} videoClassName='video-js'/>
                                
                                

                                </div>
                            </div>

                        </div>

                    </div>

                    </div>

                    </div>
                    <Footer seta={true} idioma="pt"/>
                {events.docReady()}
            </div>
        );
    }
}
