/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import BarraAcessibilidade from '../BarraAcessibilidade'
import OwlCarousel from 'react-owl-carousel2'
import events from '../../script/DOMController'
import Footer from '../Footer'
import NavBar2 from '../NavBarComponent/NavBar2'
import MenuButtons from '../MenuButtons'
import MenuButtonInfo from './MenuButtonInfo'
import Idioma from '../idiomaComponent/idioma'
import Player from '../Player'
import Hosts from '../../global/Hosts'
import {Redirect} from 'react-router-dom'
import Utils from '../Utils'
import axios from 'axios'
import CarousselDestaques from '../carousselDestaques'

class Main extends React.Component{
    
    constructor(){
       super();
        this.state={
            redirect:false,
            idAutorizado:''
        }
    }

    redirecionarAreaProfessor=()=>{
        if(Utils.getCookie('lumUserId')!==null && Utils.getCookie('lumUserId')!=='00000000D00000000000000000000002'){
            return (
                <a href={'https://www.richmond.com.br/filesrs/richmond/kidsweb/projecao/volume1/unit1/'} id='area_professor' className="bt" title="Livro Digital para Projeção"> <span>&nbsp;</span></a>                  
            )
        }
        else{
            return (
                <a href={'https://www.richmond.com.br/filesrs/richmond/kidsweb/projecao/volume1/unit1/'} id='area_professor' className="bt" title="Livro Digital para Projeção"> <span>&nbsp;</span></a>
            );
        }
    }

    redirecionarExtraResources=()=>{
        if(Utils.getCookie('lumUserId')!=null && Utils.getCookie('lumUserId')!='00000000D00000000000000000000002'){
            return (<Link to={Hosts['localhost']+'area-do-professor'} className="linkAcessar"  id='area_professor'title="Acessar a Área do Professor"/>)

        }else{
            return (<a href={Hosts['Login']} className="linkAcessar"  id='area_professor' title="Acessar a Área do Professor">
                    </a>);
        }
    }

    redirecionarAreaProfessorList=()=>{
        if(this.state.idAutorizado && Utils.getCookie('lumUserId')!=null && Utils.getCookie('lumUserId')!='00000000D00000000000000000000002'){
            return (<Link to={Hosts['localhost']+'area-do-professor'} id='area_professor'title="Área do Professor">
                        <li className="menuM">Área do Professor</li>
                    </Link>)
        }else{
            return (<a href={Hosts['Login']}><li className="menuM">Área do Professor</li></a>);
        }
    }

    videorecursos = {        
        autoplay: false,  
        language:'pt',      
        controls: true,
        width: 474,
        height:293,
        sources: [{
          src: Hosts['videos']+'TeaserOppaKeki.mp4',
          type: 'video/mp4'
        }]
      }

    videouniverso = {        
        autoplay: false,  
        language:'pt',      
        controls: true,
        width: 14,
        height:296,
        thumb: 'thumb_video_home_rodape.png',
        sources: [{
          src: Hosts['videos']+'universo-kids-web.mp4',
          type: 'video/mp4'
        }]
    }

    options = {
        loop:true,
        margin:25,
        nav:true,
        dots:false,
        center: true,
        navText: ["<img src='"+Hosts['images']+"/btLeft.png' alt='botão anterior lista'>","<img src='"+Hosts['images']+"/btRight.png' alt='botão próximo lista'>"],
        responsive:{
          0:{
            items:1
          },
          600:{
              items:1
          },
          1000:{
              items:3
          }
        }
    }
    logout(){
        localStorage.clear()
        document.cookie="lumUserId=00000000D00000000000000000000002;path=/;"
        window.location=Hosts['Logout'];
    }
    
    componentDidMount(){
        events.docReady()
        if(sessionStorage.getItem('notallowed')){
            sessionStorage.clear()
            if(window.confirm('Este usuário não possui acesso à área restrita! Deseja efetuar logout?')){
                this.logout()
            }
        }
    }

    autorizaUsuario(){
        if((Utils.getCookie('lumUserId')!==null && Utils.getCookie('lumUserId')!=='00000000D00000000000000000000002') && this.state.idAutorizado !== Utils.getCookie('lumUserId')){
            axios.post(Hosts['authApi'],{userId:Utils.getCookie('lumUserId')}).then(resp=>{
                console.log(resp.data["EhKids"] === 1)
                localStorage.setItem('userId',Utils.getCookie('lumUserId'))
                if(resp.data["EhKids"] === 1){                                
                    this.setState({idAutorizado:Utils.getCookie('lumUserId')})
                }
                if(!this.state.redirect){
                    this.setState({redirect:true})
                }
            })
        }
    }
   render(){
        if(this.state.redirect && !localStorage.getItem('redirecionado')){   
            localStorage.setItem('redirecionado', true)         
            return <Redirect to = {Hosts['localhost']+"area-do-professor"} />
        }
      return(
        <React.Fragment>
        
            <div className="topo" id="inicio">
                <BarraAcessibilidade/>
            </div>

            <div id="main" className="pt">
                <div className="home">
                    <div className="row logo">
                        <div className="laco">
                            <div className="container">
                                <div className="col-xs-12">
                                    <Idioma  idioma={"pt"} url={"en"} />
                                </div>
                                <Link to={Hosts["localhost"]} title="Acessar site Kids' Web : link interno" aria-label="Acessar site Kids' Web : link interno"><img className="img-fluid" src={Hosts['images']+"logo.png"} alt="Kids' Web"/></Link>
                            </div>
                        </div>

                        <NavBar2 lang="pt"/>                
                        
                        <div className="col-sm-12 col-md-12 col-lg-12 imgHome">
                            <img className="img-fluid kids" src={Hosts['images']+"home-ipad.png"} alt="Livros Kids' Web" usemap="#image-map"/>
                            <map name="image-map" hidefocus="false">                                
                                <area target="_blank" alt="Kids' Web 2" title="Kids' Web 2" href="https://kidsweb.com.br/assets/arquivos/flipbooks/kw2/index.html" coords="820,223,703,224,668,115,693,54,876,62" shape="poly"/>
                                <area target="_blank" alt="Kids' Web 1" title="Kids' Web 1" href="https://kidsweb.com.br/assets/arquivos/flipbooks/kw1/index.html" coords="696,208,638,30,416,26,506,355,660,325" shape="poly"/>
                                <area target="_blank" alt="Kids' Web 3" title="Kids' Web 3" href="https://kidsweb.com.br/assets/arquivos/flipbooks/kw3/index.html" coords="433,100,480,269,479,320,333,348,334,90" shape="poly"/>
                                <area target="_blank" alt="Kids' Web 4" title="Kids' Web 4" href="https://kidsweb.com.br/assets/arquivos/flipbooks/kw4/index.html" coords="332,101,216,90,216,346,331,328" shape="poly"/>
                                <area target="_blank" alt="Kids' Web 5" title="Kids' Web 5" href="https://kidsweb.com.br/assets/arquivos/flipbooks/kw5/index.html" coords="215,100,160,96,169,112,171,130,161,142,140,154,114,154,100,149,97,346,211,326" shape="poly"/>
                            </map>
                        </div>

                        <div className="container">
                
                            <div className="col-sm-12 col-md-12 col-lg-12 imgHomeMobile">
                                <img className="img-fluid kids" src={Hosts['images']+"livro-mobile.png"} alt="Livros Kids' Web"/>
                            </div>

                            <div className="col-xs-12 col-sm-10 col-md-10 col-md-offset-1 col-sm-offset-1" id="apresentacao">
                                {/* <!-- <div className="flex apresentação"> --> */}
                                <div className="seta">
                                    <a href="#destaque" className="destaque"><i className="fas fa-angle-down"></i></a>
                                </div>

                                <div className="seta2">
                                    <a href="#destaques" title="Acessar site destaques : link interno" aria-label="Acessar site destaques : link interno" className="destaque"><i className="fas fa-angle-down"></i></a>
                                </div>

                                <h2>Aprendizado Divertido e significativo</h2>

                                <p>A coleção <b><i>Kids' Web</i></b>, grande sucesso entre professores e alunos, ensina inglês de forma divertida e significativa. 
                                    A série estabelece uma forte ligação entre o aluno e o mundo da língua inglesa por meio da tecnologia, além de oferecer uma gama de componentes digitais inovadores. 
                                    <b><i> Kids' Web</i></b> respeita o desenvolvimento da criança, sem interferir na alfabetização em sua língua materna, e oferece diferentes formatos de atividades a cada etapa de crescimento.
                                </p>

                                {/* <!-- </div> --> */}
                            </div>

                        </div>

                        <div className="container">

                            <div className="flex destaque">
                                <div className="col-xs-12 col-sm-12 col-md-6 imgBoneco ipadOff" id="destaque">
                                    <img src={Hosts['images']+"personagens.png"} alt="Mia e Amigos"/>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-5 col-md-offset-1 col-lg-6 col-lg-offset-0 txtDestaque ipadOff">
                                    <h2>Destaques<br/> desta edição</h2>
                                </div>
                            </div>

                        </div>

                    </div>
                
                    <div className="col-xs-12 col-sm-12 col-md-12 ipad txtDestaqueIpad" id="destaques">
                        <div className="container">
                            <h2>Destaques desta edição</h2>
                        </div>
                    </div>
                    <div className="row ButtonsImg">
                        <MenuButtons />
                    </div>
                    <div className="row livro1">
                        <MenuButtonInfo/>           
                    </div>
                    
                
                    <CarousselDestaques/>
            
                    <div className="row livro2">
            
                        <div className="container ipadOff videoContainer">
                            
                            <div className="col-xs-12 col-md-6 col-lg-6 videoRecursos">
                                
                                {/* <Player options = {this.videorecursos} videoClassName='video-js videoRecursosPlayer'/> */}
                                <img className="img-fluid noFilter noFilterAP" src={Hosts['images']+"pack_digital.png"} alt="Pack Digital"/>
                            
                            </div>
        
                            <div className="col-xs-12 col-md-5 col-lg-6 col-lg-offset-1 txtRecursos">
                                <h2>Recursos Didáticos</h2>
                                <p><strong>Livro do Aluno</strong> com <i>workbook</i> integrado.</p> 
                                <p><strong>Revistinhas.</strong></p>
                                <p><strong><i>Kids' Web Games:</i></strong> aplicativo para acesso aos jogos ativados pela capa.</p>
                                <p><strong>Códigos <i>Real-Time View:</i></strong> atalhos para acessar conteúdos digitais.</p> 
                                <p><strong>Clipes musicais.</strong></p>
                                <p><strong>Livro Digital Interativo.</strong></p>
                                <p><strong><i>Kids' Web </i>Pastimes:</strong> almanaque digital com recursos interativos e lúdicos</p>
                                <p><strong>Animações Socioemocionais.</strong></p>
                                <p><strong>Novo Portal Educacional Richmond.</strong></p>
                            </div>                

                        </div>

                        <div className="container ipad">

                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <h2>Recursos Didáticos</h2>
                                <p><strong>Livro do Aluno</strong> com <i>workbook</i> integrado.</p> 
                                <p><strong>Revistinhas.</strong></p>
                                <p><strong><i>Kids' Web Games:</i></strong> aplicativo para acesso aos jogos ativados pela capa.</p>
                                <p><strong>Códigos <i>Real-Time View:</i></strong> atalhos para acessar conteúdos digitais.</p> 
                                <p><strong>Clipes musicais.</strong></p>
                                <p><strong>Livro Digital Interativo.</strong></p>
                                <p><strong><i>Kids' Web </i>Pastimes:</strong> almanaque digital com recursos interativos e lúdicos</p>
                                <p><strong>Animações Socioemocionais.</strong></p>
                                <p><strong>Novo Portal Educacional Richmond.</strong></p>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-6 LivroImg">
                                <img className="img-fluid" src={Hosts['images']+"pack_digital.png"} alt="Pack Digital"/>
                            </div>

                        </div>
                        
                    </div>
                    <div className="row Destaques" id="digitalpack">
                        <div className="container">
                            <div className="col-xs-12 col-md-7 col-md-offset-1 col-lg-8">
                                <h2>Digital<br/> Pack</h2>
                            </div>
                            <div className="hidden-xs hidden-sm col-md-5 col-md-offset-2">
                                <img src={Hosts['images']+"webby_aponta.png"} alt="Digital Pack"/>
                            </div>
                        </div>
                    </div>
                    <div className="row WebGames">    
                        <div className="container">    
                            <div className="col-xs-12 col-sm-12 col-md-6 ">
                                <img className="img-fluid" src={Hosts['images']+"kidsweb.png"} alt="Kids' Web games"/>
                            </div>    
                            <div className="col-xs-12 col-sm-12 col-md-6 txtGames">
                                <h4>Kids' web games</h4>
                                <p>
                                    Um aplicativo gratuito de jogos com diferentes tecnologias digitais, como realidade aumentada, ambientes em 360º e recurso anáglifo. Cada jogo introduz o conteúdo do volume de forma interativa e lúdica, contribuindo para o processo de aprendizagem. Baixe e acesse o aplicativo, mire a capa de cada livro e desfrute desse recurso divertido e educativo.
                                </p>
                                <Link to={Hosts["localhost"]+"games"} title=" Kids' Web Games" aria-label="Kids' Web Games" className="bt" refresh='true'><span>&nbsp;</span></Link>
                            </div>    
                        </div>    
                    </div>
            
                    <div className="row ItensP1">    
                        <div className="container">    
                            <div className="col-xs-12 col-sm-12 col-md-12 ">    
                                <div className="col-xs-12 col-sm-6 col-md-6 txtGames">
                                    <img className="img-fluid FirstImg" src={Hosts['images']+"img1.png"} alt="Kids' Web Pastimes"/>
                                    <h4>Kids' web pastimes</h4>
                                    <p>Um almanaque digital para cada volume com recursos interativos, como atividades, vídeos e enigmas. Apresentam uma abordagem mais lúdica a fim de estimular e engajar os alunos no aprendizado da língua inglesa.</p>
                                    <a href={Hosts['pastimes']} title=" Kids' Web Pastimes" aria-label="Kids' Web Pastimes" className="bt"><span>&nbsp;</span></a>    
                                </div>
            
                                <div className="col-xs-12 col-sm-6 col-md-6 txtGames">
                                    <img className="img-fluid FirstImg" src={Hosts['images']+"img2.png"} alt="Animações Socioemocionais"/>
                                    <h4>animações socioemocionais</h4>
                                    <p>Cinco episódios da série <b className="oppa">Oppa Kêki</b>, que narram as aventuras cotidianas de um cágado e um tatu fêmea na Amazônia. A partir das experiências que vivenciam, é possível trabalhar a língua inglesa associada à reflexão sobre diferentes habilidades socioemocionais.</p>
                                    <Link to={Hosts['localhost']+'emocionais'} title=" Animações Socioemocionais" aria-label="Animações Socioemocionais" className="bt"><span>&nbsp;</span></Link>
                                </div>                        
                            </div>
            
                            <div className="col-xs-12 col-sm-12 col-md-12 mt50">    
                                <div className="col-xs-12 col-sm-6 col-md-6 txtGames">
                                    <img className="img-fluid FirstImg" src={Hosts['images']+"img3.png"} alt="Livro Digital Interativo"/>
                                    <h4>Livro digital interativo</h4>

                                    <p>Uma versão digital e interativa do livro impresso, que permite ao aluno realizar as atividades, acessar áudios, vídeos e objetos digitais, além de possibilitar a gravação da própria voz para checar a pronúncia.</p>
                                    <a href={'https://www.richmond.com.br/filesrs/richmond/kidsweb/livrodigital/volume1/unidade1/index_prof.html'} title="Livro Digital Interativo" aria-label="Livro Digital Interativo" className="bt"><span>&nbsp;</span></a>    
                                </div>
            
                                <div className="col-xs-12 col-sm-6 col-md-6 txtGames">
                                    <img className="img-fluid FirstImg" src={Hosts['images']+"img4.png"} alt="Livro Digital para Projeção"/>
                                    <h4>Livro Digital para Projeção</h4>
                                    <p>
                                        Recurso exclusivo para o professor, para uso em lousas digitais interativas e projetores. Tem o mesmo formato do livro impresso e disponibiliza os áudios, os outros objetos digitais da coleção e as respostas das atividades, além de possuir ferramentas para marcação.
                                    </p>
                                    { this.redirecionarAreaProfessor() }
                                </div>
                                
                            </div>
            
                        </div>
            
                    </div>
            
                    <div className="row carousselAdocao" id="posadocao">
                        <div className="container">
                            <div className="col-xs-12 col-sm-8 col-md-8 col-md-offset-2 col-sm-offset-2">
                                <h2>Teacher's Extra Resources</h2>
                                <p>
                                    Além de todos os recursos para o professor, como o Livro do Professor com respostas, o Manual do Professor, com orientações e sugestões e os recursos disponíveis aos alunos, o professor <span className="adocaoBold">Kids' Web </span> também receberá:
                                </p>
                            </div>
                           
                                <div className="col-xs-12 col-sm-8 col-md-8 col-md-offset-2 col-sm-offset-2">
                                <div className="boxTeachersExtra boxTeacherGalleryPt">
                                    <OwlCarousel options = {this.options}>
                                        <div className="item"><div className="boxImg"><img className="imgCarousel" src={Hosts['images']+"resources/7.png"} alt="Livro Digital para projeção" /></div><p>Livro Digital para projeção</p></div>
                                        <div className="item"><div className="boxImg"><img className="imgCarousel" src={Hosts['images']+"resources/1.png"} alt="Novo Portal Educacional Richmond" /></div><p>Novo Portal Educacional Richmond</p></div>
                                        <div className="item"><div className="boxImg"><img className="imgCarousel" src={Hosts['images']+"resources/2.png"} alt="Planejamento de aulas" /></div><p>Planejamento de aulas</p></div>
                                        <div className="item"><div className="boxImg"><img className="imgCarousel" src={Hosts['images']+"resources/3.png"} alt="Novo fantoche" /></div><p>Novo fantoche</p></div>
                                        <div className="item"><div className="boxImg"><img className="imgCarousel" src={Hosts['images']+"resources/4.png"} alt="Pôsteres" /></div><p>Pôsteres</p></div>
                                        <div className="item"><div className="boxImg"><img className="imgCarousel" src={Hosts['images']+"resources/5.png"} alt="Flashcards" /></div><p>Flashcards</p></div>
                                        <div className="item"><div className="boxImg"><img className="imgCarousel" src={Hosts['images']+"resources/6.png"} alt="Planner anual" /></div><p>Planner anual</p></div>
                                    </OwlCarousel>
                                </div>    
                                </div>

                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="btnAcessar">
                                        {this.redirecionarExtraResources()}                                
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                    </div>
                    
                    <div className="row universo" id="universo">
            
                        <div className="container">
            
                            <div className="col-xs-12 col-sm-12 col-md-6 col-md-offset-3">
                                <h2>Universo Kids' Web</h2>
                            </div>
            
                            <div className="col-xs-12 col-sm-12 col-md-12 imgBoneco">
                                <div className="col-xs-12 col-md-7 videoUniverso">
                                    <Player options = {this.videouniverso} videoClassName='video-js videoUniversoPlayer'/>                        
                                </div>
                                <div className="col-xs-12 col-md-5 txt">
                                    <p>Fazer parte do universo <b><i>Kids' Web</i></b> significa estar conectado com o que há de melhor no ensino de inglês para crianças: recursos digitais, suporte pedagógico, material lúdico e um portal educacional completo com ferramentas diversificadas para ajudar no dia a dia em sala de aula.</p>
                                </div>
                            </div>
            
                        </div>
            
                    </div>  


                </div>

            </div>

            <Footer seta={true} idioma="pt"/> 
    
        </React.Fragment>

      )                  
    }
}

export default Main;