import Utils from '../components/Utils'
import Hosts from '../global/Hosts'
import axios from 'axios'
const isLogin = function(){
        
        if((Utils.getCookie('lumUserId')!=null && Utils.getCookie('lumUserId')!='00000000D00000000000000000000002') && localStorage.getItem('uLog')==Utils.getCookie('lumUserId')){                                           
            return true;              
        }else{
            return false;
        }
    
        // localStorage.setItem('userId',Utils.getCookie('lumUserId'))
        // axios.post(Hosts['authApi'],{userId:localStorage.getItem('userId')}).then(resp=>{
        //         //CHAMO A API PARA CONFIRMAR SE O USUÁRIO POSSUI A COLEÇÃO KIDSWEB
        //     //SETAR RETORNO DA API
        //     console.log(resp.data["EhKids"] == 1)
        //     if(resp.data["EhKids"] == 1){
        //         localStorage.setItem('idAutorizado',localStorage.getItem('userId'))
        //         return true;
        //     }else{return false;}
            
        //     })                
        // }
    } 
export default isLogin;