import React from 'react'
import BarraAcessibilidade from '../BarraAcessibilidade'
import Hosts from '../../global/Hosts'
import OwlCarousel from 'react-owl-carousel2'
import { Link } from 'react-router-dom'
import Footer from '../Footer'
import events from '../../script/DOMController'
import ListaPlayer from '../ListaPlayer'
import Idioma from '../idiomaComponent/idioma'
import LacoHeader from '../LacoHeaderComponent/LacoHeader'

export default class ClipeEn extends React.Component {

    constructor(props){
        super(props);
        this.state={
            video: '/TeaserOppaKeki.mp4',
            videoAtivo: 0
        }

        this.selecionaVideos = this.selecionaVideos.bind(this)
    }

    videoconfig = [        
        {
            autoplay: false,
            language:'en',            
            controls: true,
            width: '100%',
            height: '100%',
            bg: Hosts['videos'] + 'clipes-musicais/gen_3kw1_v_ing_f1_love_family_2019_m/cover.png',
            sources: [{
                src: Hosts['videos'] + 'clipes-musicais/gen_3kw1_v_ing_f1_love_family_2019_m/gen_v_ing_f1_love_family_2019_m.mp4',
                type: 'video/mp4'
            }]
        },           
        {
            autoplay: false,
            language:'en',
            controls: true,
            width: '100%',
            height: '100%',
            bg: Hosts['videos'] + 'clipes-musicais/gen_3kw2_v_ing_f1_alphabet_2019_m/cover.png',
            sources: [{
                src: Hosts['videos'] + 'clipes-musicais/gen_3kw2_v_ing_f1_alphabet_2019_m/gen_3kw2_v_ing_f1_alphabet_2019_m_p2.mp4',
                type: 'video/mp4'
            }]
        },
        {
            autoplay: false,
            language:'en',
            controls: true,
            width: '100%',
            height: '100%',
            bg: Hosts['videos'] + 'clipes-musicais/gen_3kw3_v_ing_f1_town_2019_m/cover.png',
            sources: [{
                src: Hosts['videos'] + 'clipes-musicais/gen_3kw3_v_ing_f1_town_2019_m/gen_3kw3_v_ing_f1_town_2019_m.mp4',
                type: 'video/mp4'
            }]
        }
    ]

    options = {
        loop:false,        
        margin:25,
        nav:false,
        dots:false,
        center: true,
        navText: ["<img src='assets/img/btLeft.png' id='btLeft' alt='botão anterior lista'>","<img src='assets/img/btRight.png' id='btRight' alt='botão próximo lista'>"],
        responsive:{
            0:{
                items:1,
                nav:true
            },
            600:{
                items:1,
                nav:true
            },
            1000:{
                items:1
            }
        }
    }    

    events = {        
        onChanged: function(event) {
            if(document.querySelectorAll(".vjs-playing").length > 0){
                document.querySelectorAll(".vjs-playing video")[0].pause();
            }
        }
    }
    
    selecionaVideos(videoArray){
        this.setState({
            videoAtivo: videoArray
        })
        this.refs.car.goTo(videoArray)
        if(document.querySelectorAll(".vjs-playing").length > 0){
            document.querySelectorAll(".vjs-playing video")[0].pause();
        }
    }

    componentDidMount(){
        events.docReady()    
    }
    
    events = {        
        onChanged: function(event) {
            if(document.querySelectorAll(".vjs-playing").length > 0){
                document.querySelectorAll(".vjs-playing video")[0].pause();
            }
        }
    }
    
    render() {
        return (
            <React.Fragment>
                <div class="topo" id="inicio">
                    <BarraAcessibilidade />
                </div>
                <div id='main'>
                
                    <div class="interno clipesMusicais">

                        {/* <!-- Logo --> */}
                        <section class="header">
                            <LacoHeader selecao="en" pagina="clipes" />
                        </section>

                        <div class="conteudo">
                            <div class="container">
                            <div class="iframeInterno socio">
                                <div class="col-xs-12 ">
                                    <div class="row">
                                        <div className=" col-sm-4 col-md-3">                                            
                                            <div className="imgClipesMusicais">
                                                <img className="webby" src={Hosts['images']+"WEBBY.png"} alt="Personagem Webby" />
                                            </div>                                             
                                        </div>
                                        <div className="col-xs-12 col-sm-7 col-md-7">
                                            <div className="txtClipesMusicais">
                                                <h1>Musical Clips</h1>                                                
                                                <p>Do you like to sing? We have prepared some musical clips for the songs from the <b><i>Kids' Web</i></b> series specially for you! Watch them, belt the music out and practice your speaking skills in English!</p>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                                
                            
                                <div class="iframeInterno socio carouselInterno">
                                    <div class="col-xs-12 ">                                    
                                        <OwlCarousel ref="car" options={this.options} events={this.events}>
                                            <div className="item">
                                                <ListaPlayer options = {this.videoconfig[0]} videoClassName='video-js' onPause={this.state.pausaVideos}/>    
                                            </div>
                                            <div className="item">
                                                <ListaPlayer options = {this.videoconfig[1]} videoClassName='video-js' onPause={this.state.pausaVideos}/>    
                                            </div>
                                            <div className="item">
                                                <ListaPlayer options = {this.videoconfig[2]} videoClassName='video-js' onPause={this.state.pausaVideos}/>    
                                            </div>
                                        </OwlCarousel>   
                                        <ul class="listavideos clipemusical">
                                            <li title="I love my family - Book 1"  class={`col-xs-4 col-sm-4 col-md-4 ${this.state.videoAtivo === 0 ? `active`: ``}`} 
                                                onClick={() => this.selecionaVideos(0)}>
                                                <img src={Hosts['images']+"clipes_musicais_book_vol1.png"} alt="Volume 1: I Love My Family"/>
                                                <h3>I Love My Family</h3>
                                            </li>
                                            <li title="My alphabet song - Book 2" class={`col-xs-4 col-sm-4 col-md-4 ${this.state.videoAtivo === 1 ? `active`: ``}`} onClick={() => this.selecionaVideos(1)}>
                                                <img src={Hosts['images']+"clipes_musicais_book_vol2.png"} alt="Volume 2: My Alphabet Song"/>
                                                <h3>My Alphabet Song</h3>
                                            </li>
                                            <li title="My Town - Book 3" class={`col-xs-4 col-sm-4 col-md-4 ${this.state.videoAtivo === 2 ? `active`: ``}`} onClick={() => this.selecionaVideos(2)}>
                                                <img src={Hosts['images']+"clipes_musicais_book_vol3.png"} alt="Volume 3: My Town"/>
                                                <h3>My Town</h3>
                                            </li>
                                        </ul>                              
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
                <Footer seta={true} idioma="en"/>
            </React.Fragment>
        );
    }
}
