import React from 'react'
import BarraAcessibilidade from '../BarraAcessibilidade'
import Hosts from '../../global/Hosts'
import OwlCarousel from 'react-owl-carousel2'
import { Link } from 'react-router-dom'
import Footer from '../Footer'
import events from '../../script/DOMController'
import ListaPlayer from '../ListaPlayer'
import Idioma from '../idiomaComponent/idioma'
import LacoHeader from '../LacoHeaderComponent/LacoHeader'

export default class EmocionaisEn extends React.Component {
    constructor(props){
        super(props);
        this.state={
            video: '/TeaserOppaKeki.mp4',
            videoAtivo: 0
        }

        this.selecionaVideos = this.selecionaVideos.bind(this)

    }

    options = {
        loop:false,
        margin:25,
        nav:false,
        dots:false,
        center: true,
        navText: ["<img src='assets/img/btLeft.png' id='btLeft' alt='botão anterior lista'>","<img src='assets/img/btRight.png' id='btRight' alt='botão próximo lista'>"],
        responsive:{
            0:{
                items:1,
                nav:true
            },
            600:{
                items:1,
                nav:true
            },
            1000:{
                items:1
            }
        }
    }    

    videoconfig = [
        {
            autoplay: false,
            language:'en',
            controls: true,
            width: '100%',
            height: '100%',
            bg: Hosts['videos'] + 'socio-emocionais/volume1/cover.png',
            sources: [{
                src: Hosts['videos'] + 'socio-emocionais/volume1/gen_3kw1_v_ing_f1_hiii_2019_m_p1.mp4',
                type: 'video/mp4'
            }]
        },
        {
            autoplay: false,
            language:'en',
            controls: true,
            width: '100%',
            height: '100%',
            bg: Hosts['videos'] + 'socio-emocionais/volume2/cover.png',
            sources: [{
                src: Hosts['videos'] + 'socio-emocionais/volume2/gen_3kw2_v_ing_f1_sunset_2019_m_p1.mp4',
                type: 'video/mp4'
            }]
        },
        {
            autoplay: false,
            language:'en',
            controls: true,
            width: '100%',
            height: '100%',
            bg: Hosts['videos'] + 'socio-emocionais/volume3/cover.png',
            sources: [{
                src: Hosts['videos'] + 'socio-emocionais/volume3/gen_3kw3_v_ing_f1_waterfall_2019_m_p1.mp4',
                type: 'video/mp4'
            }]
        },
        {
            autoplay: false,
            language:'en',
            controls: true,
            width: '100%',
            height: '100%',
            bg: Hosts['videos'] + 'socio-emocionais/volume4/cover.png',
            sources: [{
                src: Hosts['videos'] + 'socio-emocionais/volume4/gen_3kw4_v_ing_f1_olympics_2019_m_p1.mp4',
                type: 'video/mp4'
            }]
        },
        {
            autoplay: false,
            language:'en',
            controls: true,
            width: '100%',
            height: '100%',
            bg: Hosts['videos'] + 'socio-emocionais/volume5/cover.png',
            sources: [{
                src: Hosts['videos'] + 'socio-emocionais/volume5/gen_3kw5_v_ing_f1_trash_2019_m_p1.mp4',
                type: 'video/mp4'
            }]
        }
    ]

    selecionaVideos(videoArray){
        this.setState({
            videoAtivo: videoArray
        })
        this.refs.car.goTo(videoArray)
        if(document.querySelectorAll(".vjs-playing").length > 0){
            document.querySelectorAll(".vjs-playing video")[0].pause();
        }
    }

    componentDidMount(){
        events.docReady()    
    }
    
    events = {        
        onChanged: function(event) {
            if(document.querySelectorAll(".vjs-playing").length > 0){
                document.querySelectorAll(".vjs-playing video")[0].pause();
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div class="topo" id="inicio">
                    <BarraAcessibilidade />
                </div>
                <div id='main'>
                 
                    <div class="interno socioemocionais">

                        {/* <!-- Logo --> */}
                        <section class="header">
                            <LacoHeader selecao="en" pagina="emocionais" />
                        </section>

                        <div class="conteudo">
                            
                            <div class="container">
                                <div className="boxdescricao socio">
                                    <h1>Socioemotional Animations</h1>
                                    <p>How about watching animations in English?</p>
                                    <p>The five episodes of the <b><i>Oppa Kêki</i></b> series narrate the daily adventures of a tortoise and a female armadillo in the Amazon forest.</p>
                                    <p>Watch them, enjoy the characters and their ways of dealing with emotions and practice your listening skills in English!</p>
                                </div>
                                
                            
                                <div class="row iframeInterno socio carouselInterno">
                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                    
                                        <OwlCarousel ref="car" options={this.options} events={this.events}>
                                            <div className="item">
                                                <ListaPlayer options = {this.videoconfig[0]} videoClassName='video-js' onPause={this.state.pausaVideos}/>    
                                            </div>
                                            <div className="item">
                                                <ListaPlayer options = {this.videoconfig[1]} videoClassName='video-js' onPause={this.state.pausaVideos}/>
                                            </div>
                                            <div className="item">
                                                <ListaPlayer options = {this.videoconfig[2]} videoClassName='video-js' onPause={this.state.pausaVideos}/>
                                            </div>
                                            <div className="item">
                                                <ListaPlayer options = {this.videoconfig[3]} videoClassName='video-js' onPause={this.state.pausaVideos}/>
                                            </div>
                                            <div className="item">
                                                <ListaPlayer options = {this.videoconfig[4]} videoClassName='video-js' onPause={this.state.pausaVideos}/>
                                            </div>
                                        </OwlCarousel>      
                                        <ul class="listavideos">
                                            <li title="Hiiii - Book 1" class={`col-xs-3 col-sm-2 col-md-2 ${this.state.videoAtivo === 0 ? `active`: ``}`} 
                                                onClick={() => this.selecionaVideos(0)}>
                                                <img src={Hosts['images']+"lista-video-book-1.png"} alt="volume 1"/>
                                            </li>
                                            <li title="Sunset - Book 2" class={`col-xs-3 col-sm-2 col-md-2 ${this.state.videoAtivo === 1 ? `active`: ``}`} onClick={() => this.selecionaVideos(1)}>
                                                <img src={Hosts['images']+"lista-video-book-2.png"} alt="volume 2"/>
                                            </li>
                                            <li title="Waterfall - Book 3" class={`col-xs-3 col-sm-2 col-md-2 ${this.state.videoAtivo === 2 ? `active`: ``}`} onClick={() => this.selecionaVideos(2)}>
                                                <img src={Hosts['images']+"lista-video-book-3.png"} alt="volume 3"/>
                                            </li>
                                            <li title="Olympics - Book 4" class={`col-xs-3 col-sm-2 col-md-2 ${this.state.videoAtivo === 3 ? `active`: ``}`} onClick={() => this.selecionaVideos(3)}>
                                                <img src={Hosts['images']+"lista-video-book-4.png"} alt="volume 4"/>  
                                            </li>
                                            <li title="Trash - Book 5" class={`col-xs-3 col-sm-2 col-md-2 ${this.state.videoAtivo === 4 ? `active`: ``}`} onClick={() => this.selecionaVideos(4)}>
                                                <img src={Hosts['images']+"lista-video-book-5.png"} alt="volume 5"/>
                                            </li>
                                        </ul>                              
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
                <Footer seta={true} idioma="en"/>
            </React.Fragment>

        );
    }
}
