import React from 'react'
import { Link } from 'react-router-dom'
import NavBar from '../NavBar'
import Hosts from '../../global/Hosts'
import Player from '../Player'
import MenuButtonInfo from '../pt/MenuButtonInfo'
import MenuButtons from '../MenuButtons'
import BarraAcessibilidade from '../BarraAcessibilidade'
import { Redirect } from 'react-router-dom'
import Utils from '../Utils'
import axios from 'axios'
import events from '../../script/DOMController'

import OwlCarousel from 'react-owl-carousel2'
import Footer from '../Footer'
import NavBar2 from '../NavBarComponent/NavBar2'
import Idioma from '../idiomaComponent/idioma'

const urlImage = Hosts['images']

class MainEs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            idAutorizado: ''
        }
    }

    redirecionarExtraResources=()=>{
         if(Utils.getCookie('lumUserId')!=null && Utils.getCookie('lumUserId')!='00000000D00000000000000000000002') {         
            return (<a href={Hosts['areaDoProfessorEn'] } className="linkAcessar"  id='area_professor'title="Log in to the Teacher's Area"/>)
        } else {
            return (<a href={Hosts['LoginEn']} className="linkAcessar" id='area_professor' title="Log in to the Teacher's Area">
            </a>);
        }
    }

    redirecionarAreaProfessor=()=>{
        if(Utils.getCookie('lumUserId')!=null && Utils.getCookie('lumUserId')!='00000000D00000000000000000000002'){
            return ( <a href={'https://www.richmond.com.br/filesrs/richmond/kidsweb/projecao/volume1/unit1/'} 
                      title=" Digital Book (IWB)" 
                      aria-label="Digital Book (IWB)" 
                      className="bt"><span>&nbsp;</span>
                      </a>)
        }else{
            return (
                <a href={'https://www.richmond.com.br/filesrs/richmond/kidsweb/projecao/volume1/unit1/'} 
                   title=" Digital Book (IWB)" 
                   aria-label="Digital Book (IWB)" 
                   className="bt"
                  >
                   <span>&nbsp;</span>
                </a>
                );
        }
    }

    redirecionarAreaProfessorList=()=>{
        if(this.state.idAutorizado){
            return (<Link to={Hosts['areaDoProfessorEn']} id='area_professor'title="Área do Professor">
                        <li className="menuM">Teacher's Area</li>
                    </Link>)
        }else{
            return (<a href={Hosts['LoginEn']}><li className="menuM">Teacher's Area</li></a>);
        }
    }

    videorecursos = {        
        autoplay: false,  
        language:'en',      
        controls: true,
        width: 474,
        height:293,
        sources: [{
          src: Hosts['videos']+'TeaserOppaKeki.mp4',
          type: 'video/mp4'
        }]
      }

    videouniverso = {        
        autoplay: false,  
        language:'en',      
        controls: true,
        width: 14,
        height:296,
        thumb: 'thumb_video_home_rodape.png',
        sources: [{
          src: Hosts['videos']+'universo-kids-web.mp4',
          type: 'video/mp4'
        }]
    }

    options = {
        loop: true,
        margin: 25,
        nav: true,
        dots: false,
        center: true,
        navText: ["<img src='" + urlImage + "/btLeft.png' alt='botão anterior lista'>", "<img src='" + urlImage + "/btRight.png' alt='botão próximo lista'>"],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 3
            }
        }
    }


    componentDidMount() {
        events.docReady()

    }

    autorizaUsuario() {
        if ((Utils.getCookie('lumUserId') != null && Utils.getCookie('lumUserId') != '00000000D00000000000000000000002') && this.state.idAutorizado != Utils.getCookie('lumUserId')) {
            axios.post(Hosts['authApi'], { userId: Utils.getCookie('lumUserId') }).then(resp => {
                console.log(resp.data["EhKids"] === 1)
                localStorage.setItem('userId', Utils.getCookie('lumUserId'))
                if (resp.data["EhKids"] === 1) {
                    this.setState({ idAutorizado: Utils.getCookie('lumUserId') })
                }
                if (!this.state.redirect) {
                    this.setState({ redirect: true })

                }
            })
        }
    }

    render(){
        if(this.state.redirect && !localStorage.getItem('redirecionado')){   
            localStorage.setItem('redirecionado', true)         
            return <Redirect to = {Hosts['localhost']+"area-do-professor/en"} />
        }
        return (
            <div>
                <div className="topo" id="inicio">
                    <BarraAcessibilidade />
                </div>

                <div id="main" className="en">
                    <div className="home">
                        <div className="row logo">
                            {/* <!-- Logo --> */}
                            <div className="laco">
                                <div className="container">
                                    <div className="col-xs-12">
                                        <Idioma  idioma="en" url="" />
                                    </div>
                                    <Link to={Hosts['localhost'] + 'en'} title="Kids' Web Logo"><img className="img-fluid" src={urlImage + "logo.png"} alt="Kids' Web" /></Link>
                                </div>
                            </div>

                            <NavBar2 id={this.state.idAutorizado} lang="en"/>                              

                            
                            <div className="col-sm-12 col-md-12 col-lg-12 imgHome">
                                <img className="img-fluid kids" src={urlImage + "home-ipad.png"} alt="Kids' Web Books" usemap="#image-map"/>
                                <map name="image-map">                                
                                    <area target="_blank" alt="Kids' Web 2" title="Kids' Web 2" href="https://kidsweb.com.br/assets/arquivos/flipbooks/kw2/index.html" coords="820,223,703,224,668,115,693,54,876,62" shape="poly" />
                                    <area target="_blank" alt="Kids' Web 1" title="Kids' Web 1" href="https://kidsweb.com.br/assets/arquivos/flipbooks/kw1/index.html" coords="696,208,638,30,416,26,506,355,660,325" shape="poly"/>
                                    <area target="_blank" alt="Kids' Web 3" title="Kids' Web 3" href="https://kidsweb.com.br/assets/arquivos/flipbooks/kw3/index.html" coords="433,100,480,269,479,320,333,348,334,90" shape="poly"/>
                                    <area target="_blank" alt="Kids' Web 4" title="Kids' Web 4" href="https://kidsweb.com.br/assets/arquivos/flipbooks/kw4/index.html" coords="332,101,216,90,216,346,331,328" shape="poly"/>
                                    <area target="_blank" alt="Kids' Web 5" title="Kids' Web 5" href="https://kidsweb.com.br/assets/arquivos/flipbooks/kw5/index.html" coords="215,100,160,96,169,112,171,130,161,142,140,154,114,154,100,149,97,346,211,326" shape="poly"/>
                                </map>
                            </div>

                            <div className="container">

                                <div className="col-sm-12 col-md-12 col-lg-12 imgHomeMobile">
                                    <img className="img-fluid kids" src={urlImage + "livro-mobile.png"} alt="Kids' Web Books" />
                                </div>

                                <div className="col-xs-12 col-sm-10 col-md-10 col-md-offset-1 col-sm-offset-1" id="apresentacao">
                                    <div className="seta">
                                        <a href="#destaque" className="destaque"><i className="fas fa-angle-down"></i></a>
                                    </div>
                                    <div className="seta2">
                                        <a href="#destaques" className="destaque"><i className="fas fa-angle-down"></i></a>
                                    </div>
                                    <div className="destaqueBox">
                                        <h2>Fun and meaningful learning</h2>
                                        <p><b>Kids' Web</b> series, a great success among teachers and students, teaches English in a fun and meaningful way.
                                        The series establishes a strong connection between the student and the English language world through technology, besides offering a range of innovative digital components.
                                         <b> Kids' Web</b> respects the child's development without interfering with literacy in their mother tongue and offers different formats of activities at each stage of growing up.</p>
                                    </div>
                                </div>

                            </div>

                            <div className="container">

                                <div className="flex destaque">
                                    <div className="col-xs-12 col-sm-12 col-md-6 imgBoneco ipadOff" id="destaque">
                                        <img src={urlImage + "personagens.png"} alt="Mia and Friends" />
                                    </div>

                                    <div className="col-xs-12 col-sm-12 col-md-5 col-md-offset-1 col-lg-6 col-lg-offset-0 txtDestaque ipadOff">
                                        <h2>This edition's<br /> Highlights</h2>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div id="destaque" className="col-xs-12 ipad txtDestaqueIpad" id="destaques">
                            <div className="container">
                                <h2>This Edition's Highlights</h2>
                            </div>
                        </div>

                        <div className="row ButtonsImg">

                            <div className="container">

                                <div className="col-xs-12 col-md-3 item clilImg-verde" id="clil">
                                    <p className="txtclil active">
                                        <a title="CLIL and Life Skills" href="javascript:void(0)" >
                                            CLIL AND LIFE SKILLS
                                        </a>
                                    </p>
                                </div>

                                <div className="col-xs-12 col-md-3 item glossImg" id="glossario">
                                    <p>
                                        <a title="Interactive Glossary" href="javascript:void(0)">
                                            INTERACTIVE GLOSSARY
                                        </a>
                                    </p>
                                </div>

                                <div className="col-xs-12 col-md-3 item bnccImg" id="bncc">
                                    <p>
                                        <a title="Alignment to BNCC" href="javascript:void(0)">
                                        ALIGNMENT TO BNCC
                                        </a>
                                    </p>
                                </div>

                                <div className="col-xs-12 col-md-3 item bookImg" id="booklet">
                                    <p>
                                        <a title="Booklet for the Family" href="javascript:void(0)">
                                        BOOKLET FOR THE FAMILY
                                        </a>
                                    </p>
                                </div>


                            </div>

                        </div>

                        <div className="row livro1">

                            <div className="container">

                                <div id="clilinfo">
                                    <div className="col-xs-12 col-md-6" >
                                        <h2>CLIL AND LIFE SKILLS</h2>
                                        <p>
                                            At the end of the odd-numbered units, the book offers a double page spread with <b> CLIL </b>content - 
                                            with interdisciplinary work always aligned with BNCC guidelines - or <b> Life Skills </b>content,
                                            whose goal is to make students use English to reflect on relevant situations in their reality and develop socioemotional and cognitive skills.</p>
                                    </div>

                                    <div className="col-xs-12 col-md-6 LivroImg">
                                        <img className="img-fluid" src={urlImage + "img_clil.png"} alt="Clil and Life skills" />
                                    </div>
                                </div>

                                <div id="glossarioinfo" className="off">
                                    <div className="col-xs-12 col-md-6">
                                        <h2>INTERACTIVE GLOSSARY</h2>
                                        <p>
                                            The traditional bilingual glossary is now interactive in books 1 to 3. The student interacts with the glossary in a playful way, drawing and completing images and words, all in order to build their own repertoire.
                                        </p>
                                    </div>

                                    <div className="col-xs-12 col-md-6 LivroImg">
                                        <img className="img-fluid" src={urlImage + "glossario.png"} alt="Interactive Glossary" />
                                    </div>
                                </div>

                                <div id="bnccinfo" className="off">
                                    <div className="col-xs-12 col-md-6">
                                        <h2>ALIGNMENT TO BNCC</h2>
                                        <p>
                                            The <b><i>Kids' Web</i></b> 3rd edition series was entirely thought out and produced based on the skills and competencies described in the National Curricular Common Base (in Portuguese, BNCC), from its fundamental pillars to specific sections and activities within the units.
                                        </p>
                                    </div>

                                    <div className="col-xs-12 col-sm-12 col-md-6 LivroImg">
                                        <img className="img-fluid" src={urlImage + "bncc.png"} alt="ALINHAMENTO COM A BNCC" />
                                    </div>
                                </div>

                                <div id="bookletinfo" className="off">
                                    <div className="col-xs-12 col-md-6">
                                        <h2>BOOKLET FOR THE FAMILY</h2>
                                        <p>
                                            Printed for books 1 and 2, and available in digital format for other books, the booklet for the family is intended to help parents and caregivers in the process of learning a second language.
                                        </p>
                                    </div>

                                    <div className="col-xs-12 col-md-6 LivroImg">
                                        <img className="img-fluid" src={urlImage + "family.png"} alt="BOOKLET PARA A FAMÍLIA" />
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="row carousselDestaques">

                            <div className="container">

                                <div className="col-xs-12 col-sm-12 col-md-12">

                                    <OwlCarousel options={this.options}>
                                        <div className="item">
                                            <a title="CLIL and Life Skills" id="clil">
                                                <div className="col-xs-12 col-md-6 LivroImg">
                                                    <img className="img-fluid img-mobile" src={urlImage + "img_clil.png"} alt="CLIL and Life Skills" />
                                                </div>
                                                <div className="col-xs-12 col-md-6">
                                                    <p className="font">CLIL AND LIFE SKILLS</p>
                                                    <p>
                                                        At the end of the odd-numbered units, the book offers a double page spread with <b> CLIL </b> content - with interdisciplinary work always aligned with BNCC guidelines - or <b> Life Skills </b>content, whose goal is to make students use English to reflect on relevant situations in their reality and develop socioemotional and cognitive skills.
                                                    </p>
                                                </div>


                                            </a>
                                        </div>
                                        <div className="item">
                                            <a title="Glossário Interativo" id="glossario">
                                                <div className="col-xs-12 col-md-6 LivroImg">
                                                    <img className="img-fluid img-mobile" src={urlImage + "glossario.png"} alt="Interactiv Glossary" />
                                                </div>
                                                <div className="col-xs-12 col-md-6">
                                                    <p className="font">INTERACTIV GLOSSARY</p>
                                                    <p>
                                                        The traditional bilingual glossary is now interactive in books 1 to 3. The student interacts with the glossary in a playful way, drawing and completing images and words, all in order to build their own repertoire.
                                                    </p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="item">
                                            <a title="Alinhado à BNCC" id="bncc">
                                                <div className="col-xs-12 col-md-6 LivroImg">
                                                    <img className="img-fluid img-bncc" src={urlImage + "bncc.png"} alt="BNCC Alignment" />
                                                </div>
                                                <div className="col-xs-12 col-md-6">
                                                    <p className="font">ALIGNMENT TO BNCC</p>
                                                    <p>
                                                        The <b><i>Kids' Web</i></b> 3rd edition series was entirely thought out and produced based on the skills and competencies described in the National Curricular Common Base (in Portuguese, BNCC), from its fundamental pillars to specific sections and activities within the units.
                                                    </p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="item">
                                            <a title="Booklet para a Família" id="booklet">
                                                <div className="col-xs-12 col-md-6 LivroImg">
                                                    <img className="img-fluid img-mobile" src={urlImage + "family.png"} alt="Family Booklet" />
                                                </div>
                                                <div className="col-xs-12 col-md-6">
                                                    <p className="font">BOOKLET FOR THE FAMILY</p>
                                                    <p>
                                                        Printed for books 1 and 2, and available in digital format for other books, the booklet for the family is intended to help parents and caregivers in the process of learning a second language.
                                                    </p>
                                                </div>
                                            </a>
                                        </div>
                                    </OwlCarousel>

                                </div>

                            </div>

                        </div>

                        <div className="row livro2">

                            <div className="container ipadOff">

                                <div className="col-xs-12 col-md-6 videoRecursos">
                                    {/* <Player options={this.videorecursos} videoClassName='video-js videoRecursosPlayer' /> */}
                                    <img className="img-fluid noFilter noFilterAP" src={Hosts['images']+"pack_digital.png"} alt="Pack Digital"/>
                                </div>

                                <div className="col-xs-12 col-md-6 col-lg-6 col-lg-offset-1 txtRecursos">
                                    <h2>Didactic resources</h2>
                                    <p><strong>Student's Book </strong> with integrated <i> workbook</i>.</p>
                                    <p><strong>Magazines.</strong></p>
                                    <p><strong><i>Kids' Web Games: </i> </strong> app to access the games activated by the books' cover.</p>
                                    <p><strong><i>Real-Time View Codes</i></strong>: shortcuts for accessing digital content.</p>
                                    <p><strong>Musical clips.</strong></p>
                                    <p><strong>Interactive Digital Book.</strong></p>
                                    <p><strong><i>Kids' Web </i>Pastimes:</strong> digital almanac with interactive and ludic features.</p>
                                    <p><strong>Socioemotional Animations.</strong></p>
                                    <p><strong>New Richmond Educational Portal.</strong></p>
                                </div>

                            </div>

                            <div className="container ipad">

                                <div className="col-xs-12 col-md-6">
                                    <h2>Didactic resources</h2>
                                    <p><strong>Student's Book </strong> with integrated <i> workbook</i>.</p>
                                    <p><strong>Magazines.</strong></p>
                                    <p><strong><i>Kids' Web Games: </i> </strong> app to access the games activated by the books' cover.</p>
                                    <p><strong><i>Real-Time View Codes</i></strong>: shortcuts for accessing digital content.</p>
                                    <p><strong>Musical clips.</strong></p>
                                    <p><strong>Interactive Digital Book.</strong></p>
                                    <p><strong><i>Kids' Web </i>Pastimes:</strong> digital almanac with interactive and ludic features.</p>
                                    <p><strong>Socioemotional Animations.</strong></p>
                                    <p><strong>New Richmond Educational Portal.</strong></p>
                                </div>

                                <div className="col-xs-12 col-md-6 LivroImg">
                                    <img className="img-fluid" src={urlImage + "pack_digital.png"} alt="Pack Digital" />
                                </div>

                            </div>

                        </div>

                        <div className="row Destaques" id="digitalpack">
                            <div className="container">
                                <div className="col-xs-12 col-md-7 col-md-offset-1 col-lg-8">
                                    <div className="digitalPackBox">
                                        <h2>Digital<br /> Pack</h2>
                                    </div>
                                </div>
                                <div className="hidden-xs hidden-sm col-md-5 col-md-offset-2">
                                    <img src={urlImage + "webby_aponta.png"} alt="Digital Pack" />
                                </div>
                            </div>
                        </div>

                        <div className="row WebGames">
                            <div className="container">
                                <div className="col-xs-12 col-md-6 ">
                                    <img className="img-fluid" src={urlImage + "kidsweb.png"} alt="Kids' Web games" />
                                </div>
                                <div className="col-xs-12 col-md-6 txtGames">
                                    <h4>Kids' web games</h4>
                                    <p>
                                        A free game app with a variety of digital technologies, such as augmented reality, 360-degree environments and anaglyph resources. Each game introduces the contents of the book in an interactive and fun way, contributing to the learning process. Download and access the application, point your device at the cover of each book and enjoy this fun and educational resource.
                                    </p>
                                    <Link to={Hosts['localhost'] + 'games/en'} title="Kids' Web Games" aria-label="Kids' Web Games" className="bt"><span>&nbsp;</span></Link>
                                </div>
                            </div>
                        </div>

                        <div className="row ItensP1">

                            <div className="container">

                                <div className="col-xs-12">

                                    <div className="col-xs-12 col-sm-6 txtGames">
                                        <img className="img-fluid FirstImg" src={urlImage + "img1.png"} alt="Kids' Web Pastimes" />
                                        <h4>Kids' web pastimes</h4>
                                        <p>
                                            A digital almanac for each book with interactive features such as activities, videos and enigmas. It presents a more ludic approach in order to stimulate and engage students in learning the English language.
                                        </p>
                                        
                                        <a href={Hosts['pastimes']} title=" Kids' Web Pastimes" aria-label=" Kids' Web Pastimes" className="bt"><span>&nbsp;</span></a>
                                    </div>

                                    <div className="col-xs-12 col-sm-6 txtGames">
                                        <img className="img-fluid FirstImg" src={urlImage + "img2.png"} alt="Animações Socioemocionais" />
                                        <h4>SOCIOEMOTIONAL ANIMATIONS</h4>
                                        <p>Five episodes of the <b className="oppa"> Oppa Kêki </b> series which narrate the daily adventures of a tortoise and a female armadillo in the Amazon forest. From the experiences they live, it is possible to work the English language associated with reflections on different socioemotional abilities.</p>
                                        <Link to={Hosts['localhost'] + 'emocionais/en'} title=" Socioemotional Animations" aria-label="Socioemotional Animations" className="bt"><span>&nbsp;</span></Link>
                                    </div>

                                </div>

                                <div className="col-xs-12 mt50">

                                    <div className="col-xs-12 col-sm-6 txtGames">
                                        <img className="img-fluid FirstImg" src={urlImage + "img3.png"} alt="Interactive Digital Book" />
                                        <h4>Interactive digital book</h4>
                                        <p>
                                            A digital and interactive version of the printed book that allows the student to do activities, access audios, videos and digital objects, besides enabling them to record their own voice to check the pronunciation.
                                        </p>
                                        <a href={'https://www.richmond.com.br/filesrs/richmond/kidsweb/livrodigital/volume1/unidade1/index_prof.html'} title="Interactive Digital Book " aria-label=" Interactive Digital Book" className="bt"><span>&nbsp;</span></a>

                                    </div>

                                    <div className="col-xs-12 col-sm-6 txtGames">
                                        <img className="img-fluid FirstImg" src={urlImage + "img4.png"} title="Digital Book (IWB)" alt="Projection Digital Book (IWB)" />
                                        <h4>DIGITAL BOOK (IWB)</h4>
                                        <p>
                                            An exclusive feature for the teacher to be used on interactive whiteboards and projectors. It has the same format as the printed book and provides the audios, the other digital objects in the <b><i>Kids' Web</i></b> series and the answers to the activities, as well as marking tools.
                                        </p>
                                        {this.redirecionarAreaProfessor()}
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className="row carousselAdocao" id="posadocao">

                            <div className="container">
                                <div className="boxTeachersExtra">
                                    <div className="col-xs-12 col-sm-8 col-sm-offset-2">

                                        <h2>Teacher's Extra Resources</h2>
                                        <p>In addition to all the resources for the teacher, such as the Teacher's Book with answers, 
                                            the Teacher's Guide, with guidelines and suggestions, and the resources available to students, the <span className="adocaoBold"> Kids' Web </span>
                                            teacher will also receive:
                                        </p>

                                    </div>

                                    <div className="col-xs-12 col-sm-8 col-md-8 col-md-offset-2 col-sm-offset-2">
                                         <div className="boxTeacherGallery">
                                        <OwlCarousel options={this.options}>
                                            <div className="item"><div className="boxImg"><img className="imgCarousel" src={urlImage + "resources/8.png"} alt="Digital Book (IWB)" /></div><p>Digital Book (IWB)</p></div>
                                            <div className="item"><div className="boxImg"><img className="imgCarousel" src={urlImage + "resources/1.png"} alt="The new Richmond Educational Portal." /></div><p>The new Richmond Educational Portal</p></div>
                                            <div className="item"><div className="boxImg"><img className="imgCarousel" src={urlImage + "resources/9.png"} alt="Lesson planning" /></div><p>Lesson planning</p></div>
                                            <div className="item"><div className="boxImg"><img className="imgCarousel" src={urlImage + "resources/3.png"} alt="A new puppet" /></div><p>A new puppet</p></div>
                                            <div className="item"><div className="boxImg"><img className="imgCarousel" src={urlImage + "resources/4.png"} alt="Posters" /></div><p>Posters</p></div>
                                            <div className="item"><div className="boxImg"><img className="imgCarousel" src={urlImage + "resources/5.png"} alt="Flashcards" /></div><p>Flashcards</p></div>
                                            <div className="item"><div className="boxImg"><img className="imgCarousel" src={urlImage + "resources/6.png"} alt="An Annual Planner" /></div><p>An annual Planner</p></div>
                                        </OwlCarousel>
                                           </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xs-12">
                                            <div className="btnAcessar">
                                                {this.redirecionarExtraResources()}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="row universo" id="universo">
                            {/* <div className="boxUniverso"> */}
                                <div className="container">

                                    <div className="col-xs-12 col-md-6 col-md-offset-3">
                                        <h2>Kids' Web Universe</h2>
                                    </div>

                                    <div className="col-xs-12 imgBoneco">
                                        <div className="col-xs-12 col-md-7 videoUniverso">
                                            <Player options={this.videouniverso} videoClassName='video-js videoUniversoPlayer' />
                                        </div>
                                        <div className="col-xs-12 col-md-5 txt">
                                            <p>
                                                Being part of the<b><i> Kids' Web </i></b>universe means being connected to the very best in teaching English to children: digital resources, pedagogical support, fun material and a complete educational portal with many different tools to help in the daily classroom life.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            {/* </div> */}

                        </div>

                    </div>

                </div>

                <footer id="footer" className="container-fluid">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="container">
                                <div className="seta">
                                    <a href="#inicio" className="fim" title="Initiation"><i className="fas fa-angle-up"></i></a>
                                </div>
                                <a href="https://www.richmond.com.br/portal-richmond.htm" title="Access site Richmond Portal : link interno" aria-label="Access site Richmond Portal : link interno"><img src={urlImage + "logo_richmond.png"} alt="Portal Richmond" /></a>
                            </div>
                        </div>
                    </div>
                </footer>

            </div>

        )
    }
}

export default MainEs;